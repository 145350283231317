import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from 'app/shared/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit {

  loginFormSubmitted = false;
  isLoginFailed = false;
  platform: any;
  userData: any;
  checked = false;
  active = 'active';
  showpwd = false;
  submited = false;
  enable2Fa = false;
  showConfirmPassword = false;
  maskedPhoneNumer = ''
  message = '';
  responseError = '';

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  Twofact_form = new FormGroup({
    otp: new FormControl('', [Validators.required])
  })

  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService,
              private spinner: NgxSpinnerService, private modalService: NgbModal, private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {
  }


  get lf() {
    return this.loginForm.controls;
  }

  // On submit button click
  onSubmit() {
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    if (this.checked) {
      sessionStorage.setItem('email', this.loginForm.value.username)
      sessionStorage.setItem('password', this.loginForm.value.password);
    }

    this.authService.signinUser(this.loginForm.value.username, this.loginForm.value.password, this.platform)
      .then(async (res: any) => {
        // @ts-ignore
        if (res.status === 401) {
          this.isLoginFailed = true;
          this.cdr.detectChanges();
          this.spinner.hide();
          return;
        }
        this.spinner.hide();
        if (res.user.role === 'User') {
          this.authService.sendResponse(res.user.firstName);
          this.userData = JSON.parse(sessionStorage.getItem('script-capture'));
          if (res.user.twoFactorAuth.enabled) {
            await this.getAuthCode();
            this.maskedPhoneNumer = '*'.repeat(6) + this.userData.auth.data.number.split('+61')[1].slice(-4);
          } else {
            this.enable2Fa = false;
            this.router.navigate(['/cust-order-history']);
          }
          return;
        }
        if (res.user.role === 'Admin') {
          this.authService.passText.next('unselect')
          this.authService.pharmacySwitch.next(res.user.pharmacy[0]._id);
          this.router.navigate(['/dashboard']);
          return;
        }
        if (res.user.role === 'Super Admin') {
          this.authService.passText.next('select')
          this.userData = JSON.parse(sessionStorage.getItem('script-capture'));
          this.authService.sendResponse(res.user.firstName);
          this.authService.fetch_pharmacy_platformId(this.userData, this.platform).then(async resp => {
            sessionStorage.setItem('pharmacyId', resp.result.length > 0 ? resp.result[0]._id : '');
          });
          this.router.navigate(['/dashboard']);
          return;
        }
        this.authService.sendResponse(res.user.firstName);
        this.router.navigate(['/platform']);

      })
      .catch((err) => {
        this.isLoginFailed = true;
        this.spinner.hide();
        this.cdr.detectChanges();
        console.log('error: ' + err)
      });
  }

  async getAuthCode() {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.authService.getCodeAuthCode(this.userData).then((response: any) => {
      if (response.status === 500) {
        return;
      }
      this.spinner.hide();
      this.enable2Fa = true;
      this.message = response.message;
    }).catch(err => this.spinner.hide());
  }

  ngOnInit(): void {
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.loginForm.controls['username'].setValue(sessionStorage.getItem('email'));
    this.loginForm.controls['password'].setValue(sessionStorage.getItem('password'));
    if (sessionStorage.getItem('email') !== null && sessionStorage.getItem('password') !== null) {
      this.checked = true;
    }
  }

  rememberMe(event: any) {
    if (event.target.checked) {
      sessionStorage.setItem('email', this.loginForm.value.username)
      sessionStorage.setItem('password', this.loginForm.value.password);
      this.checked = true;
    } else {
      sessionStorage.removeItem('email')
      sessionStorage.removeItem('password');
      this.checked = false;
    }

  }


  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  navigate(page) {
    this.router.navigate([page]);
  }

  async authenticate_twofa() {
    this.submited = true;
    if (this.Twofact_form.invalid) {
      return;
    }

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    await this.authService.verify_2_fa(this.userData, {'six_digit_code': this.Twofact_form.value.otp}).then(async (response: any) => {
      if (response.status === 400) {
        this.responseError = '2FA code is invalid';
        this.toastr.clear();
        this.Twofact_form.reset();
        this.spinner.hide();
        this.submited = false;
        this.toastr.error(this.responseError, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 404) {
        this.responseError = 'User not found.';
        this.Twofact_form.reset();
        this.submited = false;
        this.toastr.clear();
        this.spinner.hide();
        this.toastr.error(this.responseError, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.responseError = '';
      this.spinner.hide();
      await this.router.navigate(['/cust-order-history']);
    }).catch(err => {
      this.responseError = 'Internal Server Error';
      this.Twofact_form.reset();
      this.spinner.hide();
      this.submited = false;
      this.router.navigate(['/pages/login']);
    })
  }

  reset() {
    this.enable2Fa = false;
    this.maskedPhoneNumer = '';
    this.Twofact_form.reset();
    this.submited = false;
    sessionStorage.removeItem('script-capture')
  }
}
