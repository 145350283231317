<section id="ngx-datatable-filter">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Customers</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 col-12">
                <select class="form-control" (change)="OnSearchBy($event)">
                  <option *ngFor="let ele of searchBy;" [value]="ele.value">{{ ele.title }}</option>
                </select>
              </div>
              <div class="col-md-3 col-12">
                <fieldset>
                  <div class="input-group" *ngIf="search.title === 'Name'">
                    <input type="text" #Nametextbox class="form-control"
                           (keyup.backspace)="refresh()"
                           (keyup.enter)="searchOrder(Nametextbox.value)"
                           placeholder="Enter Name">
                    <div class="input-group-append">
                      <button class="btn light-gray"
                              (click)="searchOrder(Nametextbox.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>

                  <div class="input-group" *ngIf="search.title === 'Phone Number'">
                    <input type="text" #Phonetextbox class="form-control"
                           (keyup.backspace)="refresh()"
                           maxlength="10" (keyup.enter)="searchOrder(Phonetextbox.value)"
                           placeholder="Enter Phone Number">
                    <div class="input-group-append">
                      <button class="btn light-gray"

                              (click)="searchOrder(Phonetextbox.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>
                  <div class="input-group" *ngIf="search.title === 'Email'">
                    <input type="email" #Emailtextbox class="form-control"
                           (keyup.backspace)="refresh()"
                           (keyup.enter)="searchOrder(Emailtextbox.value)"
                           placeholder="Enter Email">
                    <div class="input-group-append">
                      <button class="btn light-gray"
                              (click)="searchOrder(Emailtextbox.value)"
                              type="button"><i class="ft-search"></i></button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <h3 class="text-center card-title" *ngIf="rows.length === 0">Customers not found</h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-striped m-0" *ngIf="rows.length > 0">
                      <thead class="text-center">
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody class="text-center">
                      <tr *ngFor="let customer of rows">
                        <td>{{ customer.slNumber }}</td>
                        <td>{{ customer.firstName === null ? ' ' : customer.firstName }}
                          {{ customer.middleName === null ? ' ' : customer.middleName }}
                          {{ customer.lastName === null ? ' ' : customer.lastName }}
                        </td>
                        <td>{{ customer.email }}</td>
                        <td>{{ customer.number }}</td>
                        <td>
                          <a href="javascript:;" class="primary ml-1" (click)="open(content,customer)">
                            <i class="ft-eye"></i>
                          </a>
                        </td>

                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                  <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize * 10"
                                  (pageChange)="pagination($event)"
                                  [(page)]="page" [maxSize]="5" [rotate]="true"
                                  [boundaryLinks]="true"></ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #content let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Customer Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="resetClose(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Media object starts -->
    <div style="max-height: 570px; overflow-y: auto; overflow-x:hidden;">

      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end align-items-center">
          <div *ngIf="this.selectedCustomer.Status === 'verified'">
            <a class="btn btn-sm bg-light-secondary px-3 py-1 mr-2" (click)="openConfirm(blacklist,'md')">
              <i class="icon-ban"></i>
            </a>
          </div>
          <div *ngIf="this.selectedCustomer.Status === 'blackListed' || this.selectedCustomer.Status === 'pending'"
               (click)="openConfirm(verify,'lg')">
            <a class="btn btn-sm bg-light-success px-3 py-1 mr-2">
              <i class="ft-check-circle"></i>
            </a>
          </div>
          <div *ngIf="this.selectedCustomer.Status === 'unverified' || this.selectedCustomer.Status === 'Unverified'">
            <a class="btn btn-sm bg-light-success px-3 py-1 mr-2" (click)="openConfirm(verify,'lg')"><i
              class="ft-check-circle"></i></a>
          </div>
        </div>
      </div>
      <!-- Media object ends -->

      <div class="row">
        <div class="col-12">
          <!-- Card data starts -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">First Name</label>
                  <p>{{ this.selectedCustomer.userId?.firstName }}</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Middle Name</label>
                  <p>{{ this.selectedCustomer.userId?.middleName }}</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Last Name</label>
                  <p>{{ this.selectedCustomer.userId?.lastName }}</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Email</label>
                  <p>{{ this.selectedCustomer.userId?.email }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Phone Number</label>
                  <p>{{ this.selectedCustomer.userId?.number }}</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Status</label><br>
                  <p class="badge text-capitalize" [ngClass]="{'bg-light-success':this.selectedCustomer.Status === 'verified',
                      'bg-light-secondary':this.selectedCustomer.Status === 'blackListed',
                           'bg-light-danger':this.selectedCustomer.Status === 'unverified'}">{{ this.selectedCustomer.Status }}</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Date Of Birth</label>
                  <p>{{ this.selectedCustomer?.dateOfBirth === 'Invalid date' ? ' ' : this.selectedCustomer?.dateOfBirth.split('T')[0].split('-').reverse().join('-') }}</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12">
                  <label class="form-label font-weight-bold">Land Line Number</label>
                  <p>{{ this.selectedCustomer.userId?.landLineNumber }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-4 col-lg-3 col-sm-12" *ngIf="frontImage!== ' '">
                  <label class="form-label font-weight-bold">License Front
                    <i
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Front License"
                      (click)="downloadScript(this.frontImage)"></i>
                  </label><br>
                  <img [src]="this.frontImage" alt="" width="50%">
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12" *ngIf="backImage!== ' '">
                  <label class="form-label font-weight-bold">License Back
                    <i
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Back License"
                      (click)="downloadScript(this.backImage)"></i>
                  </label><br>
                  <img [src]="this.backImage" alt="" width="50%">
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12" *ngIf="this.medicareCard!== ''">
                  <label class="form-label font-weight-bold">Medicare Card
                    <i
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Medicare Card"
                      (click)="downloadScript(this.medicareCard)"></i>
                  </label><br>
                  <img [src]="this.medicareCard" alt="" width="50%">
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-sm-12" *ngIf="this.penssionCard!==''">
                  <label class="form-label font-weight-bold">Pension Card
                    <i
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Pension Card"
                      (click)="downloadScript(this.penssionCard)"></i>
                  </label><br>
                  <img [src]="this.penssionCard" alt="" width="50%">
                </div>
              </div>
              <div *ngIf="this.selectedCustomer?.userId?.addresses.length > 0" class="table-responsive px-2 py-2">
                <table class="table">
                  <thead>
                  <th>Address Type</th>
                  <th>Address</th>
                  <th>Suburb</th>
                  <th>State</th>
                  <th>Postcode</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let addr of this.selectedCustomer?.userId?.addresses">
                    <th>{{ addr?.addressType }}</th>
                    <td>{{ addr?.address }}</td>
                    <td>{{ addr?.suburb }}</td>
                    <td>{{ addr?.state }}</td>
                    <td>{{ addr?.postcode }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <h3>Customer Orders</h3>
              <ul ngbNav #basicnav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink (click)="this.page1 = 1;loadCustomerOrders();">Order History</a>
                  <ng-template ngbNavContent>
                    <table class="table text-center m-0" *ngIf="customerOrders.length > 0">
                      <thead>
                      <tr>
                        <th>Order No</th>
                        <th>Order By</th>
                        <th>Date and Time</th>
                        <th>Order Status</th>
                        <th class="text-left">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let order of customerOrders">
                        <td>
                          {{ order.orderNo }}
                        </td>
                        <td>{{ order.name }}</td>
                        <td>{{ order.createdAt | date: 'mediumDate' }} {{ order.createdAt | date: 'shortTime' }}</td>
                        <td class="text-capitalize">{{ order.status }}</td>
                        <td class="text-left">
                          <button class="badge secondary-btn btn"
                                  (click)="ViewOrder(order,viewOrder)">View
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="card-footer">
                      <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize1 * 10"
                                      (pageChange)="Orders_pagination($event)"
                                      [(page)]="page1" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                      </ngb-pagination>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink (click)="this.page2; loadCustmerPreOrders();">Scripts On File</a>
                  <ng-template ngbNavContent>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>Token</th>
                          <th>Repeats</th>
                          <th>Next Dispense Date</th>
                          <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let medi of upcomingOrders">
                          <td class="text-uppercase">{{ medi.orderedItem[0].token }}</td>
                          <td>{{ medi.orderedItem[0].repeats }}</td>
                          <td>{{ medi.orderDate | date: 'mediumDate' }}</td>
                          <td>
                            <div class="d-flex">
                              <button class="badge secondary-btn btn" (click)="ViewOrder(medi,viewOrder,'myscripts')">
                                View
                              </button>
                              <button class="badge primary-btn btn text-white ml-1"
                                      *ngIf="!medi.isAfterDate"
                                      (click)="openModal1(confirmReOrderModal1,medi,'Do you have approval to dispense the script','md')">
                                Advanced Request
                              </button>
                              <button class="badge bg-light-info btn ml-1"
                                      *ngIf="medi.isAfterDate"
                                      (click)="openModal(Modal1,medi)">Order
                              </button>
                            </div>

                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-footer">
                      <ngb-pagination class="float-right" [collectionSize]="this.maxSize2 * 10"
                                      (pageChange)="PreOrders_pagination($event,'preOrder')"
                                      [(page)]="page2" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                      </ngb-pagination>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="basicnav" class="mt-2"></div>
            </div>
          </div>
          <!-- Card data ends -->
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmReOrderModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ this.message }}</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger"
                (click)="openModal1(Modal2,this.selectedOrder,'Do you have approval to dispense the script','md')">Yes
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #Modal2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Repeat Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let order of selectedOrder.orderedItem;let i = index;">
      <div class="col-md-12 col-12">
        <label>Repeat Token</label>
        <div class="d-flex">
          <input type="text" class="form-control square text-uppercase size" [value]="order.token" name="token"
                 #newToken id="item{{i}}">
          <button class="btn badge primary-btn ml-2"
                  (click)="advanceRequest(newToken.value,this.selectedOrder,i,modal)">Re-Order
          </button>
        </div>
        <p *ngIf="valid" class="text-danger">Invalid Token</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <label class="font-weight-bold">Customer Notes</label>
        <div class="d-flex">
                <textarea rows="3" cols="3" #noteArea
                          class="form-control square"></textarea>
          <button class="btn-submit" [disabled]="disablebtn" placement="top" ngbTooltip="Save"
                  (click)="sendNote(noteArea)"><i
            class="ft-check-square text-success"></i></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #verify let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Update customer status</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4 col-12">
        <label class="font-weight-bold">Name</label>
        <p>{{ this.selectedCustomer?.userId.firstName }} {{
            this.selectedCustomer?.userId.middleName === null || this.selectedCustomer?.userId.middleName === '' ? ' ' :
              this.selectedCustomer?.userId.middleName
          }} {{ this.selectedCustomer?.userId.lastName }}</p>
      </div>
      <div class="col-md-4 col-12">
        <label class="font-weight-bold">Email</label>
        <p>{{ this.selectedCustomer.userId.email }}</p>
      </div>
      <div class="col-md-4 col-12">
        <label class="font-weight-bold">Mobile Number</label>
        <p>{{ this.selectedCustomer?.userId.number }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-12">
        <label class="font-weight-bold">Date Of Birth</label>
        <p>{{ dateOfBirth }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12" *ngIf="this.selectedCustomer?.licenseFront_temp_encrypted !== 'IA=='">
        <label class="font-weight-bold">Front License
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Front License"
            (click)="downloadScript(this.licenseFront)"></i>
        </label><br>
        <img *ngIf="this.selectedCustomer?.licenseFront_temp_encrypted !== 'IA=='" [src]="this.licenseFront" alt=""
             width="300" height="200">
      </div>
      <div class="col-md-6 col-12" *ngIf="this.selectedCustomer?.licenseBack_temp_encrypted !== 'IA=='">
        <label class="font-weight-bold">Back License
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Back License"
            (click)="downloadScript(this.licenseBack)"></i>
        </label><br>
        <img *ngIf="this.selectedCustomer?.licenseBack_temp_encrypted !== 'IA=='" [src]="this.licenseBack" alt=""
             width="300"
             height="200" class="ml-2">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 col-12" *ngIf="this.selectedCustomer?.medicareCardNumber !== 'IA=='">
        <label class="font-weight-bold">Medicare Card</label>
        <i
          class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Medicare Card"
          (click)="downloadScript(this.medicareCard)"></i>
        <br>
        <img *ngIf="this.selectedCustomer?.medicareCardNumber !== 'IA=='" [src]="this.medicareLicense" alt=""
             width="300"
             height="200"
             class="ml-2">
      </div>
      <div class="col-md-6 col-12" *ngIf="this.selectedCustomer?.pensionCardNumber !== 'IA=='">
        <label class="font-weight-bold">Pension Card
          <i
            class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Pension Card"
            (click)="downloadScript(this.pensionCardNumber)"></i>
        </label><br>
        <img *ngIf="this.selectedCustomer?.pensionCardNumber !== 'IA=='" [src]="this.pensionCardNumber" alt=""
             width="300"
             height="200"
             class="ml-2">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 col-12 text-center">
        <button type="button" class="btn btn-outline-success mx-2" (click)="changeStatus('verified')">Verify</button>
        <button type="button" class="btn btn-outline-danger" (click)="changeStatus('blackListed')">Blacklist</button>
      </div>
    </div>

  </div>
</ng-template>


<ng-template #Modal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Repeat Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <table class="table m-0">
        <thead>
        <tr>
          <th>Repeat Token</th>
          <th>Number of Repeats</th>
          <th>Repeat Duration</th>
          <th>Action</th>
        </tr>
        </thead>
        <tr *ngFor="let order of selectedOrder.orderedItem;let i = index;">
          <td>
            <input type="text" class="form-control square" name="token" #newToken id="order{{order._id}}"
                   value="{{order.token}}">
          </td>
          <td>
            <input type="number" class="form-control square" id="number{{order._id}}" value="{{order.repeats}}"
                   disabled
                   name="token"
                   #number_of_Repeats>
          </td>
          <td>
            <input type="number" class="form-control square" min="0" id="repeats{{i}}" #selectDuration
                   [value]="order.duration">
          </td>
          <td>
            <!--            <button class="btn primary-color"-->
            <!--                    (click)="upDatefillDetails(selectDuration.value,newToken.value,number_of_Repeats.value,this.selectedOrder,i)">-->
            <!--              Edit-->
            <!--            </button>-->
            <button class="btn secondary-btn ml-2"
                    (click)="fillDetails(selectDuration.value,newToken.value,number_of_Repeats.value,this.selectedOrder,i,modal)">
              Re-Order
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>


<ng-template #blacklist let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Update customer status</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body text-center">
    <button type="button" class="btn btn-outline-dark mx-2" (click)="changeStatus('blackListed')">Blacklist</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
  </div>
</ng-template>


<ng-template #AddCustomer let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Customer</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="userRegisteration" (ngSubmit)="onSubmit()" *ngIf="hideRegForm">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-1">First Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-1"
                     formControlName="firstName"
              >
              <p class="text-danger" *ngIf="submitted && rf.firstName.invalid">FirstName is required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-2">Middle Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-2"
                     formControlName="middleName">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-3">Last Name</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-3" formControlName="lastName">
              <p class="text-danger" *ngIf="submitted && rf.lastName.invalid">LastName is required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-5">Phone Number</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-5" formControlName="number"
                     maxlength="10" minlength="8">
              <p class="text-danger" *ngIf="submitted && rf.number.invalid">Phone number is required</p>
              <p class="text-danger" *ngIf="rf.number.hasError('pattern')">Invalid Phone number</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-4">Email</label>
            <div class="col-md-9">
              <input type="text" class="form-control square" id="horizontal-form-4" formControlName="email">
              <p class="text-danger" *ngIf="submitted && rf.email.invalid">Email is required</p>
              <p class="text-danger" *ngIf="rf.email.hasError('email')">Invalid email</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-1">Password</label>
            <div class="col-md-9">
              <input type="password" class="form-control square" formControlName="password">
              <p class="text-danger" *ngIf="submitted && rf.password.invalid">Password is required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="horizontal-form-2">Confirm Password</label>
            <div class="col-md-9">
              <input type="password" class="form-control square" formControlName="confirm_pwd">
              <p class="text-danger" *ngIf="submitted && rf.confirm_pwd.invalid">Confirm password is
                required</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <button type="submit" class="btn primary-color mb-2"><i class="ft-check-square mr-1"></i>Save</button>
        </div>
      </div>
    </form>
    <form [formGroup]="addressForm" (ngSubmit)="submitAddress()" *ngIf="showAddress">
      <h4>Customer Address Details</h4>
      <div class="row">
        <div class="col-6">
          <label>Address</label>
          <input type="text" class="form-control square" formControlName="address">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['address'].invalid">Address is required</p>
        </div>
        <div class="col-6">
          <label>Suburb</label>
          <input type="text" class="form-control square" formControlName="suburb">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['suburb'].invalid">Suburb is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label>State</label>
          <input type="text" class="form-control square" formControlName="state">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['state'].invalid">State is required</p>
        </div>
        <div class="col-6">
          <label>Postcode</label>
          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['postcode'].invalid">Postcode is required</p>
          <p class="text-danger" *ngIf="addressForm.controls['postcode'].hasError('pattern')">Invalid postcode</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-right">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #viewOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Order - #{{ selectedOrder.orderNo }}</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-dialog-scrollable">
    <div class="modal-body">
      <h5 class="font-weight-bold font-italic">User Information</h5>
      <hr>
      <div class="row">
        <div class="col-md-12 col-xl-9 col-12" style="overflow-y: scroll !important;">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-8 col-12">
              <div class="row">
                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                  <label class="font-weight-bold">Name</label>
                  <p>{{ selectedOrder?.name }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Mobile Number</label>
                  <p>{{ selectedOrder?.mobile }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Email</label>
                  <p>{{ selectedOrder?.email }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Order By</label><br>
                  <p>{{ selectedOrder?.orderedBy }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Ordered Date and Time</label><br>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                     *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                  <label class="font-weight-bold">Cancellation Reason</label><br>
                  <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                  <p
                    *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
                </div>

                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Delivery Type</label><br>
                  <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                  <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Status</label><br>
                  <span
                    class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : this.selectedCustomer.Status === 'verified','bg-light-dark btn' : this.selectedCustomer.Status === 'blacklisted',
                            'bg-light-danger btn': this.selectedCustomer.Status === 'unverified'}">
                              {{ this.selectedCustomer.Status }}</span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                  <label class="font-weight-bold">Order Status</label><br>
                  <span
                    *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                    class="text-capitalize">{{ selectedOrder.status }}</span>
                  <span
                    *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                    class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                  <label class="font-weight-bold">Address</label>
                  <p>
                    <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                  </p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                     *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                  <label class="font-weight-bold">Pickup Name</label><br>
                  <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                     *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                  <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                    <i
                      *ngIf="showUrl"
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                      (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                  </label>
                  <br>
                  <div *ngIf="showUrl">
                    <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                         height="40"
                         (click)="isCollapsed = !isCollapsed"
                         [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                    <div id="collapseExample" [ngbCollapse]="isCollapsed">
                      <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                    </div>
                  </div>
                  <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-4 col-12">
              <div class="chat-container d-none d-sm-none d-xl-block d-md-none d-lg-none">
                <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand"
                                                                                 [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'"
                                                                                 class="cursor-pointer float-right"></i>
                </div>
                <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                  <div class="messages">
                    <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                      <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                         *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                         (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                      {{ note.fallowUp_note }}
                      <p
                        class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                        {{ note.note_addedBy_userName }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="input-area">
                  <!--                  <input type="text"  id="message-input" #messageBox placeholder="Type a note..." >-->
                  <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox)" #messageBox
                            placeholder="Type a note..."></textarea>
                  <button class="btn btn-success ml-2" style="height: 40px;" [disabled]="disablebtn"
                          (click)="onEnterKey(messageBox)">
                    <i class="ft-send mr-1"></i>
                  </button>
                </div>
              </div>
              <div class="d-xl-none">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                    <div class="chat-container">
                      <div class="chat-header bg-secondary">Customer Follow Up Note</div>
                      <div class="chat-box">
                        <div class="messages">
                          <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                            <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                               *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                               (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                            {{ note.fallowUp_note }}
                            <p
                              class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                              {{ note.note_addedBy_userName }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="input-area">
                        <!--                  <input type="text"  id="message-input" #messageBox placeholder="Type a note..." >-->
                        <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox1)" #messageBox1
                                  placeholder="Type a note..."></textarea>
                        <button class="btn btn-success ml-2" style="height: 40px;" [disabled]="disablebtn"
                                (click)="onEnterKey(messageBox1)">
                          <i class="ft-send mr-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                    <div class="chat-container">
                      <div class="chat-header bg-secondary">Pharmacy Notes</div>
                      <div class="chat-box">
                        <div class="messages">
                          <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                            <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                               *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                               (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                            {{ note.fallowUp_note }}
                            <p
                              class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                              {{ note.note_addedBy_userName }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="input-area">
            <textarea cols="31" rows="1" (keyup.enter)="onEnterKey(pharmacy_Notes1,'pharmacyNote')" #pharmacy_Notes1
                      placeholder="Type a note..."></textarea>
                        <button class="btn bg-success text-white ml-2" style="height: 40px;" [disabled]="disablebtn"
                                (click)="onEnterKey(pharmacy_Notes1,'pharmacyNote')">
                          <i class="ft-send mr-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="d-flex">
            <h5 class="font-weight-bold font-italic">User Script</h5>
          </div>
          <div class="table-responsive">
            <table class="table items-table">
              <thead>
              <tr>
                <!--          <th class="text-left">QR Code</th>-->
                <th>Token Number</th>
                <th>Script Info</th>
                <th>Brand Name</th>
                <th>Product Name</th>
                <th>Repeats</th>
                <th>Quantity</th>
                <th
                  *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">
                  Partial Shipped Quantity
                </th>
                <th
                  *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">
                  Remaining Quantity
                </th>
                <th>Price</th>
                <th>Instruction</th>
                <th>Note</th>
                <ng-container *ngIf="selectedOrder.status === 'new'">
                  <th>Actions</th>
                </ng-container>
                <th>Backorder</th>

              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.orderedItems;let i = index;">
                <!--          <td class="py-0">-->
                <!--            <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
                <!--          </td>-->
                <td>
                  <div class="d-flex" *ngIf="modalType === 'myscripts'">
                    <input type="text" class="text-uppercase" name="token" #newToken id="item{{i}}"
                           [value]="item.token">
                    <span class="ft-check-square text-success cursor-pointer ml-2 mt-1"
                          [disabled]="valid"
                          (click)="updateToken(newToken.value,i,modal)">
                  </span>
                  </div>
                  <span class="text-uppercase" *ngIf="modalType !== 'myscripts'">{{ item.token }}</span>
                </td>
                <td class="text-center">
                  <div class="d-flex">
                    <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                         class="cursor-pointer"
                         src="{{ item?.scriptData?.uploadedScript }}"
                         alt="" width="60" height="40"
                         (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
                    <span ngbTooltip="Download ScriptImage"
                          *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                          class="ft-download-cloud cursor-pointer ml-2"
                          (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
                  </div>
                  <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
                     href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
                  </a>
                </td>
                <td>{{ item?.product_brand_name }}</td>
                <td class=" cursor-pointer"
                    (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
                </td>
                <td>{{ item?.repeats }}</td>
                <td>{{ item?.quantity }}</td>
                <td
                  *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.shippedQuantity }}
                </td>
                <td
                  *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.remainingQuantity }}
                </td>
                <td class="text-center">${{ item?.price }}</td>
                <td>{{ item?.instruction }}</td>
                <td>{{ item?.note }}</td>
                <td *ngIf="item.isBackOrder" class="text-center">
                 <span
                   class="badge bg-light-warning btn text-capitalize">Backorder</span>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right"><b>Item Total</b></td>
                <td>${{ selectedOrder?.itemsCost | number:'1.2-2' }}</td>
              </tr>
              <tr *ngIf="!selectedOrder?.pickup">
                <td colspan="5" class="text-right"><b>Delivery Charge</b></td>
                <td>
                  ${{ selectedOrder.freightCharge }}
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right"><b>Total Amount:</b></td>
                <td><p class="font-weight-bold font-medium-1">${{ selectedOrder?.totalAmount | number:'1.2-2' }}<br>
                  <span
                    class="font-weight-normal font-small-3">*Includes GST of ${{ selectedOrder.taxAmount | number:'1.2-2' }}</span>
                </p></td>
              </tr>

              </tbody>
            </table>
          </div>
          <br>
          <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
          <hr>
          <div class="row">
            <div class="col-md-4 col-sm-12 col-lg-4 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Name</label>
                <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
              </div>
              <div class="col-12 mt-2">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-lg-4 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Email</label>
                <p>{{ selectedOrder?.pharmacyId?.email }}</p>
              </div>
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Address</label>
                <p>{{
                    selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                    ' ' + selectedOrder?.pharmacyId?.postcode
                  }}</p>
              </div>
            </div>
          </div>
          <!--Payment Details-->
          <br>
          <h5 class="font-weight-bold font-italic">Payment Details
          </h5>
          <div
            *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
            <hr>
            <div class="table-responsive" *ngIf="selectedOrder?.orderPaymentDetails.length > 0">
              <table class="table table-bordered m-0">
                <thead class="table-header">
                <tr>
                  <th>Order No</th>
                  <th>Amount</th>
                  <th>Order Date</th>
                  <th>Payment Method</th>
                  <th>Transaction Number</th>
                  <th>Date & Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                  <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                  <td>$ {{ payment?.orderPayment_Amount }}</td>
                  <td>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </td>
                  <td>{{ payment?.orderPayment_method === 'Internet' ? 'Online Payment' : payment?.orderPayment_method }}</td>
                  <td>{{ payment?.orderPayment_reference }}</td>
                  <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!--      Additional Cost Details-->
          <br>
          <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional
            Costs</h5>
          <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
            <div class="col-md-12 col-12">
              <div class="table-responsive">
                <table class="table table-bordered m-0">
                  <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Payment Status</th>
                    <th>Amount Added By</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                    <td>$ {{ extracost.amount }}</td>
                    <td>{{ extracost.description }}</td>
                    <td>   <span [ngClass]="extracost.paid ? 'bg-light-success' : 'bg-light-secondary'"
                                 class="badge ">{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</span></td>
                    <td>{{ extracost.amount_addedBy_userName }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--      Delivery Details-->
          <br>
          <div *ngIf="selectedOrder?.shippingDetails.length > 0">
            <h5 class="font-weight-bold font-italic">Delivery Details</h5>
            <hr>
            <div class="table-responsive">
              <table class="table table-bordered m-0">
                <thead class="table-header">
                <tr>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Carrier Number</th>
                  <th>Connote Number</th>
                  <th>Delivery Date & Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                  <td class="cursor-pointer"
                      (click)="viewChildOrder(ele?.itemShippedDetails[0]?.orderNumber,productsShipped)">
                    <span style="text-decoration: underline;">{{ ele?.itemShippedDetails[0]?.orderNumber }}</span>
                  </td>
                  <td>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </td>
                  <td>{{ ele?.freightCarrier }}</td>
                  <td>{{ ele?.trackingNo }}</td>
                  <td>{{ ele?.deliveredDate  | date:'mediumDate' }} {{ ele?.deliveredDate  | date:'shortTime' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-3 col-12">

          <div class="chat-container mx-1 my-1 d-none d-sm-none d-xl-block d-md-none d-lg-none">
            <div class="chat-header bg-secondary">Pharmacy Notes</div>
            <div class="pharmacy-chat-box">
              <div class="messages">
                <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                  <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                     *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                     (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                  {{ note.fallowUp_note }}
                  <p
                    class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                    {{ note.note_addedBy_userName }}
                  </p>
                </div>
              </div>
              <div class="input-area">
            <textarea cols="31" rows="1" (keyup.enter)="onEnterKey(pharmacy_Notes,'pharmacyNote')" #pharmacy_Notes
                      placeholder="Type a note..."></textarea>
                <button class="btn bg-success text-white ml-2" style="height: 40px;" [disabled]="disablebtn"
                        (click)="onEnterKey(pharmacy_Notes,'pharmacyNote')">
                  <i class="ft-send mr-1"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</ng-template>


<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>


<ng-template #productsShipped let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Shipped Products - #{{ childOrder.orderNo }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table items-table">
        <thead>
        <tr>
          <!--          <th class="text-left">QR Code</th>-->
          <th>Token Number</th>
          <th>Brand Name</th>
          <th>Product Name</th>
          <th>Ordered Quantity</th>
          <th>Shipped Quantity</th>
          <th>Remaining Quantity</th>
        </tr>
        </thead>
        <tr *ngFor="let item of this.childOrder?.orderedItem;let i = index;">
          <td><span (click)="copyToken(item?.token)" class="text-UpperCase"><i
            class="cursor-pointer ft-copy mr-1 text-primary"
            ngbTooltip="Token copied"
            triggers="click:blur"></i></span>{{ item?.token }}
          </td>
          <td>{{ item?.product_brand_name }}</td>
          <td class=" cursor-pointer" (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}</td>
          <td>{{ item?.quantity }}</td>
          <td>{{ item?.shippedQuantity }}</td>
          <td>{{ item?.remainingQuantity }}</td>
        </tr>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>
