import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loginFormSubmitted = false;
  userData: any;
  OtpSubmit = false;
  isLoginFailed = false;
  showChangePassowrd = false;
  visible = false;
  forgotpassWrdForm = new FormGroup({
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  });
  OtpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
    new_password: new FormControl('', Validators.required),
    confirm_password: new FormControl('', Validators.required),
  });
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })
  phoneNumberForm = new FormGroup({
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')])
  })
  showUserMng: boolean;
  platform: any;
  showpwd = false;
  showEmailBlock = false;
  showPhoneBlock = false;
  showConfirmPassword = false;
  submitted = false;


  constructor(private router: Router, private authService: AuthService,
              private spinner: NgxSpinnerService, private toastr: ToastrService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
  }

  // On submit button click
  onSubmit() {
    this.loginFormSubmitted = true;
    if (this.forgotpassWrdForm.invalid) {
      return;
    }
    const body = {
      number: '+61' + this.forgotpassWrdForm.value.number
    }
    this.authService.forgotpassword(body, this.platform).then((resp: any) => {
      this.loginFormSubmitted = false;

    }).catch((err) => {
    });
    this.visible = true;
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  onSubmitOTP() {
    this.OtpSubmit = true;
    if (this.OtpForm.invalid) {
      return;
    }
    if (this.OtpForm.value.new_password !== this.OtpForm.value.confirm_password) {
      this.toastr.error('Password Mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.OtpSubmit = false;
      return;
    }
    const body = {
      number: '+61' + this.forgotpassWrdForm.value.number,
      otp: this.OtpForm.value.otp,
      newPassword: this.OtpForm.value.new_password,
      confirmPassword: this.OtpForm.value.confirm_password,
    }
    this.authService.changePassword(body, this.platform).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Successs', {
          positionClass: 'toast-top-center'
        });

      } else {
        this.toastr.success('Password Changed SuccessFully', 'Successs', {
          positionClass: 'toast-top-center'
        });
        this.OtpSubmit = false;
        this.router.navigate(['/pages/login']);
      }
    }).catch((err) => {
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
    });
    this.OtpSubmit = false;
  }


  sendEmail() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }
    const body = {
      'resetUrl': window.location.origin + '/pages/resetPassword'
    }
    this.authService.sendPassword_reset_link(body, this.emailForm.value.email, this.platform, 'email').then((response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('The email with the link sent successfully', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.router.navigate(['/pages/login']);
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Internal Server Error', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }

  sendSMS() {
    this.submitted = true;
    if (this.phoneNumberForm.invalid) {
      return;
    }
    const body = {
      'resetUrl': window.location.origin + '/pages/resetPassword'
    }
    this.authService.sendPassword_reset_link(body, '61' + this.phoneNumberForm.value.number, this.platform, 'number').then((response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('The email with the link sent successfully', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.router.navigate(['/pages/login']);
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Internal Server Error', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }

  showBlock(type: string) {
    this.submitted = false;
    if (type === 'email') {
      this.showEmailBlock = !this.showEmailBlock;
      this.showPhoneBlock = false;
    }
    if (type === 'phone') {
      this.showPhoneBlock = !this.showPhoneBlock;
      this.showEmailBlock = false;
    }
  }
}
