import {Injectable} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {TDocumentDefinitions, Table} from 'pdfmake/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PdfServiceService {

  constructor() {
    // @ts-ignore
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  generatePdf(auditLogs: any[]) {
    const documentDefinition: TDocumentDefinitions = {
      content: [
        {text: 'Audit Logs', style: 'header'},
        '\n\n',
        {
          layout: 'fullwidth',
          table: {
            headerRows: 1,
            widths: [200, 'auto', 'auto', 'auto'], // Example widths in pixels, adjust as needed
            body: [
              ['Description', 'Date', 'Time', 'Table'], // Header row
              ...auditLogs.map(log => [
                log.Description,
                log.Date,
                log.Time,
                log.Table === undefined ? '' : log.Table,
              ])
            ]
          } as Table
        }
      ],
      styles: {
        header: {
          fontSize: 13,
          bold: true
        }
      }
    };
    pdfMake.createPdf(documentDefinition).download('audit_logs.pdf');
  }
}
