import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Router} from '@angular/router';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Order} from '../orders/order';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const moment = require('moment');
const today = moment(new Date()).format();

@Component({
  selector: 'app-upcoming-orders',
  templateUrl: './upcoming-orders.component.html',
  styleUrls: ['./upcoming-orders.component.scss']
})
export class UpcomingOrdersComponent implements OnInit {

  userData: any;
  platform: any;
  hoveredDate: any;
  showUrl = false;
  EnableRepeat = false;
  multipleOrders: any = [];
  pharmacy: any;
  rows: any = [];
  maxsize = 0;
  expand = false;
  closeResult = '';
  page = 1;
  pharmacyUser: any;
  customerNotes = [];
  pharmacyNotes = [];
  disablebtn = false;
  selectedOrder: Order | any;
  orderedItems: any = [];
  from: any;
  to: any;
  valid = false;
  fromDate: any;
  toDate: any;
  today: any;
  token_updation = {
    'orderId': '',
    'newToken': '',
    'repeats': '',
    'duration': '',
  }
  filterByCalendar = false;
  isCollapsed = true;
  rowHeight: any;
  showEditToken = false;
  nextpageLength = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;
  message = 'There are no orders currently';
  searchBy = [
    {title: 'Name', value: 'customerName', type: 'filter'},
    {title: 'Phone Number', value: 'customerNumber', type: 'filter'},
    {title: 'Email', value: 'customerEmail', type: 'filter'},
    {title: 'Order No', value: 'orderNumber', type: 'filter'}
  ];
  search = {title: 'Name', value: 'customerName', type: 'order'};
  searchValue = '';

  viewScript = '';
  private debounceTimer: any;

  constructor(private authService: AuthService, private spinner: NgxSpinnerService, private storage: LocalStorageService,
              private modalService: NgbModal, private router: Router, private downloadFile: DownloadFileService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.from = moment(new Date()).format('YYYY-MM-DD');
    this.today = moment(new Date()).format('YYYY-MM-DD');
    this.to = moment(new Date()).format('YYYY-MM-DD');
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        sessionStorage.setItem('pharmacyId', data);
        this.loadingScreen();
        this.loadOrders();
        this.authService.fetch_particular_pharmacy(this.userData).then((resp) => {
          this.pharmacy = resp;
        });
      }
      return;
    });
    this.loadOrders();
    this.authService.fetch_particular_pharmacy(this.userData).then((resp) => {
      this.pharmacy = resp;
    });
  }


  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }

  loadOrders() {
    this.searchValue = '';
    if (this.filterByCalendar) {
      this.authService.fetchOrders_by_date(this.userData, this.from, this.to, this.page).then((resp: any) => {
        this.rows = resp.results.filter((order) => {
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          order.isAfterDate = moment(new Date()).isAfter(moment(order.orderDate));
          order.isCurrentDate = moment(order.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          return !order.deleted;
        });
        this.maxsize = resp.totalPages;
      }).catch(err => {
        console.log(err)
      });
    } else {
      this.authService.fetchOrders(this.userData, 'preOrder', 1, sessionStorage.getItem('pharmacyId')).then((resp: any) => {
        this.maxsize = resp.totalPages;
        this.spinner.hide();
        this.rows = resp.results.filter((data) => {
          if (moment(data.orderDate).format('l') === moment(today).format('l')) {
            data.enableBtn = true;
          } else {
            data.enableBtn = false;
          }
          data.convertedDispenseData = moment(data.orderDate).format('YYYY-MM-DD');
          data.isAfterDate = moment(new Date()).isAfter(moment(data.orderDate));
          data.isCurrentDate = moment(data.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          return !data.deleted;
        });
        this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
      }).catch(err => {
        this.spinner.hide();
      })
    }

  }

  updateToken(token, index, modal) {
    let body = [];
    if (token.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Please Enter the Token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    body.push({
      propName: 'orderedItem.' + index + '.' + 'token',
      value: token
    });

    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Token updation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Token Updated Successfully', 'Updated', {
          positionClass: 'toast-top-center'
        });
        body = [];
        this.selectedOrder = resp.updatedBody;
        this.orderedItems = this.selectedOrder.orderedItem;
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
        });
        this.loadOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Ordered items udpation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  onDateSelection(date: NgbDate) {
    this.filterByCalendar = true;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }


    if (this.toDate !== undefined && this.toDate !== null) {
      const x = moment(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day).format('YYYY-MM-DD');
      const {day, month, year} = this.toDate;
      const y = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
      this.from = x;
      this.to = y;
      this.authService.fetchOrders_by_date(this.userData, x,
        y, this.page).then((resp: any) => {
        this.rows = resp.results.filter((order) => {
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          order.isAfterDate = moment(new Date()).isAfter(moment(order.orderDate));
          order.isCurrentDate = moment(order.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          return !order.deleted;
        });
        this.maxsize = resp.totalPages;
      }).catch(err => {
        console.log(err)
      });
    }
  }


  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  open(content, order, size = 'full-screen-modal') {
    this.expand = false;
    this.isCollapsed = true;
    this.disablebtn = false;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.pharmacyUser = response.userId;
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      this.pharmacyNotes = this.selectedOrder.notes.filter(data => !data.customerNote);
      this.customerNotes = this.selectedOrder.notes.filter(data => data.customerNote);
      this.orderedItems = response.orderedItem;
      this.orderedItems.map((data) => {
        this.selectedOrder.totalCost += data.price;
        this.selectedOrder.totalQuantity += data.quantity;
      })
      await this.modalService.open(content, {windowClass: size, backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.key === 'x' || event.key === 'X') && event.target instanceof HTMLElement && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
      this.modalService.dismissAll('x-key-press');
    }
  }

  nextpage() {
    this.page++;
    if (this.search.type !== 'filter') {
      this.authService.fetchOrders(this.userData, 'preOrder', this.page, sessionStorage.getItem('pharmacyId')).then((resp: any) => {
        this.rows = resp.orders.filter((data) => {
          if (moment(data.orderDate).format('l') === moment(today).format('l')) {
            data.enableBtn = true;
          } else {
            data.enableBtn = false;
          }
          data.convertedDispenseData = moment(data.orderDate).format('YYYY-MM-DD');
          return data;
        });
        this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
        const tempPage = this.page;
        this.authService.fetchOrders(this.userData, 'preOrder', tempPage + 1, sessionStorage.getItem('pharmacyId')).then((nextpageResponse: any) => {
          this.nextpageLength = nextpageResponse.orders.filter((data) => {
            return !data.issueReported;
          });
        });
      });
    } else {
      this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, this.page, 'preOrder').then((response: any) => {
        if (response.status === 403) {
          this.toastr.error(response.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.rows = response.results.filter((data) => {
            return !data.issueReported && data.status === 'preOrder';
          });
          this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
          let tempPage = this.page;
          this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, tempPage + 1, 'preOrder').then((nextpageResponse: any) => {
            this.nextpageLength = nextpageResponse.results.filter((data) => {
              return !data.issueReported && data.status === 'preOrder';
            });
          });
        }
      });
    }

  }

  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.collapseAllRows();
    this.rowHeight = row.height;
    if (row.expand) {
      this.tableRowDetails.rowDetail.collapseAllRows();
      this.rows.filter(itm => {
        itm.expand = false;
      })
    } else {
      this.tableRowDetails.rowDetail.toggleExpandRow(row);
      row.expand = true;
    }
  }

  previous() {
    this.page--;
    if (this.search.type !== 'filter') {
      if (this.page > 0 && this.page !== 0) {
        this.authService.fetchOrders(this.userData, 'preOrder', this.page, sessionStorage.getItem('pharmacyId')).then((resp: any) => {
          this.rows = resp.orders.filter((data) => {
            data.convertedDispenseData = moment(data.orderDate).format('YYYY-MM-DD');
            return data;
          });
          this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
          const tempPage = this.page;
          this.authService.fetchOrders(this.userData, 'preOrder', tempPage + 1, sessionStorage.getItem('pharmacyId')).then((nextpageResponse: any) => {
            this.nextpageLength = nextpageResponse.orders.filter((data) => {
              return !data.issueReported
            });
          });
        });
      } else {
        this.page = 1;
      }
    } else {
      if (this.page > 0 && this.page !== 0) {
        this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, this.page, 'preOrder').then((response: any) => {
          this.rows = response.orders.filter((data) => {
            data.convertedDispenseData = moment(data.orderDate).format('YYYY-MM-DD');
            return data;
          });
          this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
          let tempPage = this.page;
          this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, tempPage + 1, 'preOrder').then((nextpageResponse: any) => {
            this.nextpageLength = nextpageResponse.orders.filter((data) => {
              return !data.issueReported
            });
          });
        });
      } else {
        this.page = 1;
      }
    }

  }


  openModal(modal: any, order: any) {
    this.selectedOrder = order;
    this.modalService.open(modal, {size: 'xl', backdrop: 'static'})
  }

  acceptOrderForm(order, content, status) {
    if (order.userId.pharmacyUserStatus === 'unverified') {
      this.toastr.info('Please Verify User', 'Info!', {
        positionClass: 'toast-top-center'
      });
    } else {
      const body = {
        status: status
      }
      this.authService.accept_Order(this.userData, order, body).then((resp: any) => {
        if (resp.status === 404) {
          this.toastr.error(resp.error.error, 'Error!', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.toastr.error('Failed to accepted the order', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 400) {
          this.toastr.info(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.success('Orders accepted Successfully', 'Message', {
            positionClass: 'toast-top-center'
          });
          this.loadOrders();
        }
      }).catch(err => {
        this.toastr.error('Failed to accepte the order', 'Error', {
          positionClass: 'toast-top-center'
        });
      })
    }
    this.modalService.dismissAll(content)
  }

  validateToken(value: string) {
    const pattern = /[a-zA-Z0-9]/;
    if (!pattern.test(value)) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  fillDetails(duration, newToken, number_of_repeats, Order, index, modal: any) {

    if (Number(number_of_repeats) < 0) {
      this.toastr.error('Invalid Repeats', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (Number(duration) < 0) {
      this.toastr.error('Invalid Repeat Interval', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (newToken === '') {
      this.toastr.error('Please Provide the token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    let obj = {
      'orderId': '', 'items': []
    }
    this.token_updation.newToken = newToken === '' ? ' ' : newToken;
    this.token_updation.repeats = number_of_repeats === '' ? ' ' : number_of_repeats;
    this.token_updation.duration = duration === '' ? ' ' : duration;
    this.token_updation.orderId = Order._id;
    obj.orderId = Order._id;
    obj.items.push({
      'token': newToken,
      'repeats': this.token_updation.repeats,
      'duration': duration
    });
    this.authService.re_order(this.userData, this.selectedOrder, this.token_updation).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('Re-order Failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Re-ordered Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
        this.EnableRepeat = false;
        this.token_updation = {
          'orderId': '',
          'newToken': '',
          'repeats': '',
          'duration': '',
        }
        modal.dismiss('Cross-Click');
      }
    })
  }

  OnChange(order, event) {
    if (event.target.checked) {
      this.multipleOrders.push(order._id);
    } else {
      const res = this.multipleOrders.filter((data) => {
        return data !== order._id;
      });
      this.multipleOrders = res;
    }
  }


  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  submitOrders() {
    this.authService.reOrder_MultiOrders(this.userData, {'orderIds': this.multipleOrders}).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 404) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Orders Placed Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
      }
    })
  }


  updateTokenValue(index, token, order) {
    const body = [];
    body.push({
      propName: 'orderedItem.' + index + '.token', value: token
    })

    this.authService.update_order(this.userData, body, order).then((resp) => {
      this.toastr.success('Token Updated Successfully', 'Success', {
        positionClass: 'toast-top-center'
      });
      this.loadOrders();
      this.showEditToken = false;
    })
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }




  onEnterKey(note, noteType = 'Customer') {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.debounceTimer = null;
      this.sendNote(note, noteType = 'Customer');
    }, 300);
  }

  sendNote(note: HTMLInputElement, noteType = 'Customer') {
    if (note.value.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Please add a note', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = {'fallowUp_note': note.value};
    this.authService.update_note(this.userData, body, this.selectedOrder, noteType === 'Customer').then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disablebtn = false;
      } else {
        this.toastr.clear();
        this.toastr.info('Note updated', 'Info', {
          positionClass: 'toast-top-center'
        });
        note.value = '';
        this.disablebtn = false;
        this.pharmacyNotes = resp.notes.filter(data => !data.customerNote);
        this.customerNotes = resp.notes.filter(data => data.customerNote);
      }
    }).catch(er => {
      this.disablebtn = false;
    })
  }

  delete_Note(note) {
    this.authService.deleteNote(this.userData, this.selectedOrder, note).then((response: any) => {
      if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error('Note deletion failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Note deleted successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.pharmacyNotes = response.order.notes.filter(data => !data.customerNote);
        this.customerNotes = response.order.notes.filter(data => data.customerNote);
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Note deletion failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  openModal1(Modal1: any, medi: any, message: string, size = 'xl') {
    this.selectedOrder = medi;
    this.disablebtn = false;
    this.message = message;
    this.modalService.open(Modal1, {size: size, backdrop: 'static'});
  }

  advanceRequest(newToken, Order, index: number, modal: any) {
    if (newToken === '') {
      this.toastr.clear();
      this.toastr.error('Please Provide the token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const obj = {
      'orderId': '', 'items': []
    }
    this.token_updation.newToken = newToken === '' ? ' ' : newToken;
    this.token_updation.repeats = ''
    this.token_updation.duration = ''
    this.token_updation.orderId = Order._id;
    obj.orderId = Order._id;
    obj.items.push({
      'token': newToken,
    });
    this.reOrder(modal);

  }

  reOrder(modal: any) {
    this.authService.re_order(this.userData, this.selectedOrder, this.token_updation).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Re-order Failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Re-ordered Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
        this.EnableRepeat = false;
        this.token_updation = {
          'orderId': '',
          'newToken': '',
          'repeats': '',
          'duration': '',
        }
        modal.dismiss('Cross-Click');
        this.modalService.dismissAll();
      }
    });
  }

  searchOrder(value) {
    this.search.type = 'filter';
    if (value.trim() === '') {
      this.toastr.error('Enter ' + this.search.title, 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.search.title === 'Phone Number') {
      this.searchValue = value;
      this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, 1, 'preOrder').then((response: any) => {
        this.maxsize = response.totalPages;
        this.rows = response.results.filter((order) => {
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          return order.status === 'preOrder';
        });
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    } else if (this.search.title === 'Order No') {
      const pattern = /\d/;
      if (!pattern.test(value)) {
        this.toastr.error('Invalid ' + this.search.title, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.searchValue = value;
        this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, 1, 'preOrder').then((response: any) => {
          this.maxsize = response.totalPages;
          this.rows = response.results.filter((order) => {
            order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
            return order.status === 'preOrder';
          });
          if (this.rows.length === 0) {
            this.toastr.error('Orders not found', 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
        })
      }
    } else if (this.search.title === 'Email') {
      this.searchValue = value;
      this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, 1, 'preOrder').then((response: any) => {
        this.rows = response.results.filter((order) => {
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          return order.status === 'preOrder';
        });
        this.maxsize = response.totalPages;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      })
    } else {
      this.searchValue = value;
      this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, 1, 'preOrder').then((response: any) => {
        this.rows = response.results.filter((order) => {
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          return order.status === 'preOrder';
        });
        this.maxsize = response.totalPages;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    }
  }

  OnSearchBy(event) {
    this.search = this.searchBy.find((data: any) => {
      return data.value === event.target.value;
    });
  }

  deleteOrder(modal) {
    this.authService.deleteOrder(this.userData, this.selectedOrder).then((response) => {
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        modal.dismiss('Cross-Click');
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        modal.dismiss('Cross-Click');
      } else {
        this.toastr.clear();
        this.toastr.error(response.message, 'Deleted!', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
      modal.dismiss('Cross-Click');
    });
  }

  pagination(page: number) {
    this.page = page;
    if (this.filterByCalendar) {
      this.authService.fetchOrders_by_date(this.userData, this.from,
        this.to, this.page).then((resp: any) => {
        this.rows = resp.results.filter((order) => {
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          order.isAfterDate = moment(new Date()).isAfter(moment(order.orderDate));
          order.isCurrentDate = moment(order.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          return !order.deleted;
        });
        this.maxsize = resp.totalPages;
      }).catch(err => {
        console.log(err)
      });
    } else {
      if (this.search.type !== 'filter') {
        this.authService.fetchOrders(this.userData, 'preOrder', this.page, sessionStorage.getItem('pharmacyId')).then((resp: any) => {
          this.maxsize = resp.totalPages;
          this.rows = resp.results.filter((data) => {
            if (moment(data.orderDate).format('l') === moment(today).format('l')) {
              data.enableBtn = true;
            } else {
              data.enableBtn = false;
            }
            data.convertedDispenseData = moment(data.orderDate).format('YYYY-MM-DD');
            data.isAfterDate = moment(new Date()).isAfter(moment(data.orderDate));
            data.isCurrentDate = moment(data.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
            return data;
          });
          this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
        });
      } else {
        this.authService.searchOrder(this.userData, this.pharmacy, this.search.value, this.searchValue, this.page, 'preOrder').then((response: any) => {
          if (response.status === 403) {
            this.toastr.error(response.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
          } else {
            this.rows = response.results.filter((data) => {
              return !data.issueReported && data.status === 'preOrder';
            });
            this.maxsize = response.totalPages;
            this.rows.sort((a, b) => moment(b.orderDate, 'YYYY-MM-DD').toDate() - moment(a.orderDate, 'YYYY-MM-DD').toDate());
          }
        });
      }
    }
  }
}
