<section id="ngx-datatable-filter" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Pharmacy Details</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <!-- ngx-datatable-filter -->
            <div class="row">
              <div class="col-6">
                <fieldset class="form-group">
                  <label for="ngx-filter-ref">Search:
                    <input id="ngx-filter-ref"
                           (keyup)="filterupdate($event)"
                           class="form-control form-control-sm d-inline-block width-200" type="text"
                           placeholder="Search Pharmacy"/>
                  </label>
                </fieldset>
              </div>
              <div class="col-6">
                <button class="float-right btn secondary-btn" (click)="open(content)"><i
                  class="ft-plus-circle mr-1"></i>Create Pharmacy
                </button>
              </div>
            </div>
            <section id="shopping-cart">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-content">
                      <div class="card-body table-responsive">
                        <table class="table text-center m-0">
                          <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let pharmacy of phamacyList">
                            <td>{{ pharmacy.pharmacy_name }}</td>
                            <td>{{ pharmacy.email }}</td>
                            <td>{{ pharmacy.phoneNumber }}</td>
                            <td>
                              <a class="info" (click)="openView(view,pharmacy)">
                                <i class="ft-eye font-medium-3"></i>
                              </a>
                              <a class="primary ml-2" (click)="openEditModal(editpharmacy,pharmacy)">
                                <i class="ft-edit font-medium-3"></i>
                              </a> <a class="danger ml-2" (click)="openDeletModal(deleteModal,pharmacy)">
                              <i class="ft-trash font-medium-3"></i>
                            </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button class="btn secondary-btn" (click)="previous()">Previous</button>
                      <button class="btn primary-btn float-right" (click)="nextPageData()"
                              [disabled]="nextPage.length === 0">Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Create Pharmacy</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="pharmacy" (ngSubmit)="createPharmacy(modal,backOrdersTag,passsword,confirm_passsword)">
      <h5 class="modal-title">Basic Details</h5>
      <hr>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Pharmacy Name<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="pharmacy_name" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['pharmacy_name'].hasError('required')">
            Pharmacy Name is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Email<small class="text-danger font-small-3">*</small></label>
          <input type="email" formControlName="email" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['email'].hasError('required')">
            Email is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['email'].hasError('email')">
            Invalid Email
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Phone Number<small class="text-danger font-small-3">*</small></label>
          <input type="tel" formControlName="number" class="form-control" maxlength="10">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['number'].hasError('required')">
            Phone number is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['number'].hasError('pattern')">
            Invalid Phone number
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 col-12">
          <label>Address<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="address" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['address'].hasError('required')">
            Address is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Suburb<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="suburb" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['suburb'].hasError('required')">
            Suburb is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>State<small class="text-danger font-small-3">*</small></label>
          <select class="form-control" formControlName="state">
            <option selected disabled>Select</option>
            <option *ngFor="let state of states" [value]="state">{{ state }}</option>
          </select>
          <!--          <input type="text" formControlName="state" class="form-control">-->
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['state'].hasError('required')">
            State is required
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 col-12">
          <label>Postcode<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="postcode" class="form-control" maxlength="4">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['postcode'].hasError('required')">
            Postcode is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['postcode'].hasError('pattern')">
            Invalid postcode
          </p>
        </div>

        <div class="col-md-4 col-12">
          <label>Delivery Type<small class="text-danger font-small-3">*</small></label>
          <div class="p-0">
            <a class="btn ml-1" *ngFor="let ele of arr;let i = index"
               [ngClass]="ele.title === message ? 'primary-btn text-white' : 'btn-light text-dark'"
               (click)="single_select(ele)"
            >{{ ele.title }}</a>
          </div>
          <p class="text-danger" *ngIf="deliverType === '' && submitted">
            Delivery Type is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Back Orders
            <i class="ft-info cursor-pointer ml-1" aria-controls="collapseExample" (click)="collapse = !collapse"
               [attr.aria-expanded]="!collapse"></i></label><br>
          <p id="collapseExample font-small-2" style="font-style: italic; font-size: 14px;" [ngbCollapse]="collapse">
            Activating this toggle allows the pharmacy to accept backorders.</p>
          <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
            <input type="checkbox" #backOrdersTag class="custom-control-input"
                   (change)="backOrders = backOrdersTag.checked" id="custom-switch-5" [checked]="backOrders">
            <label class="custom-control-label mr-1" for="custom-switch-5">
              <span>{{ backOrders ? 'Active' : 'Inactive' }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 col-12">
          <label>Latitude<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="latitude" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['latitude'].hasError('required')">
            Latitude is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['latitude'].hasError('pattern')">
            Invalid latitude
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Longitude<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="longitude" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['longitude'].hasError('required')">
            Longitude is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['longitude'].hasError('pattern')">
            Invalid longitude
          </p>
        </div>
        <div class="col-md-4 col-12" *ngIf="this.deliverType === 'delivery' || this.deliverType === 'both'">
          <label>Delivery Charge<small class="text-danger font-small-3">*</small></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input formControlName="freightCharge" type="text" class="form-control"
                   aria-label="Amount (to the nearest dollar)">
          </div>
          <p class="text-danger" *ngIf="pharmacy.controls['freightCharge'].hasError('pattern')">
            Invalid Delivery Charge
          </p>
          <p class="text-danger" *ngIf="(this.pharmacy.controls['freightCharge'].value === null && deliverType === 'both')
|| (this.pharmacy.controls['freightCharge'].value === null && deliverType === 'delivery')
">
            Delivery Charge is required
          </p>
        </div>
      </div>

      <h5 class="modal-title mt-1">Delivery Partner Integration</h5>
      <hr>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Freight API Key</label>
          <input type="text" formControlName="freightAPIKey" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIKey'].hasError('required')">
            Freight API Key is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight API Password</label>
          <input type="password" formControlName="freightAPIPassword" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIPassword'].hasError('required')">
            Freight API Password is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight Carer</label>
          <input type="text" formControlName="freightCarrier" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightCarrier'].hasError('required')">
            Freight Carer is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight API Account</label>
          <input type="text" formControlName="freightAPIAccount" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIAccount'].hasError('required')">
            Freight API Account is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight API MasterAccount</label>
          <input type="text" formControlName="freightAPIMasterAccount" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIMasterAccount'].hasError('required')">
            Freight API MasterAccount is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Payment Carer</label>
          <input type="text" formControlName="paymentCarrier" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['paymentCarrier'].hasError('required')">
            Payment Carer is required
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Freight API URL</label>
          <input type="text" formControlName="freightAPIURL" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIURL'].hasError('required')">
            Freight API URL is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Enable Freight API</label><br>
          <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
            <input type="checkbox" class="custom-control-input" id="custom-switch-6"
                   (change)="enable_fright_api($event)">
            <label class="custom-control-label mr-1" for="custom-switch-6">
              <span>{{ freightUsingAPI ? 'Active' : 'Inactive' }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Password<small class="text-danger font-small-3">*</small></label>
          <div class="input-group">
            <input type="password" #passsword class="form-control" placeholder="Password"
                   required>
            <div class="input-group-prepend">
              <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')">
                <i [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
            </div>
          </div>
          <p class="text-danger" *ngIf="submitted && passsword.value === ''">
            Password is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Confirm Password<small class="text-danger font-small-3">*</small></label>
          <div class="input-group">
            <input type="password" #confirm_passsword class="form-control" placeholder="Password"
                   required>
            <div class="input-group-prepend">
              <span class="input-group-text cursor-pointer" (click)="showPassword(confirm_passsword,'confirmPassword')">
                <i [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
            </div>
          </div>
          <p class="text-danger" *ngIf="submitted && confirm_passsword.value === ''">
            Confirm password is required
          </p>
        </div>
      </div>
      <h5 class="mt-1 modal-title">Payment Details</h5>
      <hr>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Payment Type<small class="text-danger font-small-3">*</small><i class="ft-info ml-1"
                                                                                 (click)="collapse1 = !collapse1"
                                                                                 [attr.aria-expanded]="!collapse1"></i></label><br>
          <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
            <input type="checkbox" class="custom-control-input" id="custom-switch-21" [checked]="!paymentType"
                   (change)="paymentType = !paymentType">
            <label class="custom-control-label mr-1" for="custom-switch-21">
              <span>{{ paymentType ? 'Manual' : 'Online' }}</span>
            </label>
          </div>
          <p class="text-capitalize text-italic" aria-controls="collapseExample1" [ngbCollapse]="collapse1">
            {{
              paymentType ? 'By choosing\n' +
                '            the manual payment option, online payment is disabled.' : 'By selecting\n' +
                '            the online payment option, the customer will receive payment links through both SMS and email'
            }}
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Payment Username</label>
          <input type="text" class="form-control" [(ngModel)]="this.paymentDataBody.paymentUserName" [ngModelOptions]="{standalone: true}"
                 [readonly]="paymentType">
          <p class="text-danger" *ngIf="this.paymentDataBody.paymentUserName.trim() === '' && !paymentType">Payment
            Username is
            required</p>
        </div>
        <div class="col-md-4 col-6">
          <label class="form-label">Payment password</label>
            <input type="password" class="form-control" [(ngModel)]="this.paymentDataBody.password" [ngModelOptions]="{standalone: true}" [readonly]="paymentType">
          <p class="text-danger" *ngIf="this.paymentDataBody.password.trim() === '' && !paymentType">Payment password is
            required</p>
        </div>
        <div class="col-md-4 col-6">
          <label class="form-label">Biller Code</label>
          <input type="text" class="form-control" [(ngModel)]="this.paymentDataBody.billerCode" [ngModelOptions]="{standalone: true}"
                 [readonly]="paymentType">
          <p class="text-danger" *ngIf="this.paymentDataBody.billerCode.trim() === '' && !paymentType">Biller Code is
            required</p>
        </div>
        <div class="col-md-4 col-6">
          <label class="form-label">Merchant Number</label>
          <input type="text" class="form-control" [(ngModel)]="this.paymentDataBody.merchantNumber" [ngModelOptions]="{standalone: true}"
                 [readonly]="paymentType">
        </div>
      </div>
      <div class="text-center px-2 py-2">
        <button class="btn primary-btn" type="submit" [disabled]="disbleBtn">Save</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editpharmacy let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Edit Pharmacy</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="pharmacy" (ngSubmit)="editPharmacy(toogle_backOrders)">
      <h5 class="modal-title">Basic Details</h5>
      <hr>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Pharmacy Name<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="pharmacy_name" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['pharmacy_name'].hasError('required')">
            Pharmacy name is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Email<small class="text-danger font-small-3">*</small></label>
          <input type="email" formControlName="email" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['email'].hasError('required')">
            Email is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['email'].hasError('email')">
            Invalid Email
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Phone Number<small class="text-danger font-small-3">*</small></label>
          <input type="tel" formControlName="number" class="form-control" maxlength="10">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['number'].hasError('required')">
            Phone number is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['number'].hasError('pattern')">
            Invalid Phone number
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Address<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="address" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['address'].hasError('required')">
            Address is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Suburb<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="suburb" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['suburb'].hasError('required')">
            Suburb is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>State<small class="text-danger font-small-3">*</small></label>
          <select class="form-control" formControlName="state">
            <option selected disabled>Select</option>
            <option *ngFor="let state of states" [value]="state">{{ state }}</option>
          </select>
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['state'].hasError('required')">
            State is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Postcode<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="postcode" class="form-control" maxlength="4">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['postcode'].hasError('required')">
            Postcode is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['postcode'].hasError('pattern')">
            Invalid postcode
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Delivery Type<small class="text-danger font-small-3">*</small></label>
          <div class="p-0">
            <a class="btn ml-1" *ngFor="let ele of arr;let i = index"
               [ngClass]="ele.title === message || ele.value === message  ? 'primary-btn text-white' : 'btn-light text-dark'"
               (click)="single_select(ele)"
            >{{ ele.title }}</a>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <label>Back Orders
            <i class="ft-info cursor-pointer ml-1" aria-controls="collapseExample1" (click)="collapse = !collapse"
               [attr.aria-expanded]="!collapse"></i></label><br>
          <p id="collapseExample1" style="font-style: italic; font-size: 14px;" [ngbCollapse]="collapse">Activating this
            toggle allows the pharmacy to accept backorders.</p>
          <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
            <input type="checkbox" class="custom-control-input" id="custom-switch-4"
                   (change)="backOrders = toogle_backOrders.checked" #toogle_backOrders [checked]="backOrders">
            <label class="custom-control-label mr-1" for="custom-switch-4">
              <span>{{ backOrders ? 'Active' : 'Inactive' }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Latitude<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="latitude" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['latitude'].hasError('required')">
            Latitude is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['latitude'].hasError('pattern')">
            Invalid latitude
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Longitude<small class="text-danger font-small-3">*</small></label>
          <input type="text" formControlName="longitude" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['longitude'].hasError('required')">
            Longitude is required
          </p>
          <p class="text-danger" *ngIf="pharmacy.controls['longitude'].hasError('pattern')">
            Invalid longitude
          </p>
        </div>
        <div class="col-md-4 col-12"
             *ngIf="this.deliverType === 'delivery' || this.message === 'delivery' || this.message === 'both' || this.deliverType === 'both' ">
          <label>Delivery Charge<small class="text-danger font-small-3">*</small></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input formControlName="freightCharge" type="text" class="form-control"
                   aria-label="Amount (to the nearest dollar)">
          </div>
        </div>

      </div>
      <h5 class="modal-title mt-2">Delivery Partner Integration</h5>
      <hr>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Freight API Key</label>
          <input type="text" formControlName="freightAPIKey" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIKey'].hasError('required')">
            Freight API Key is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight API Password</label>
          <input type="password" formControlName="freightAPIPassword" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIPassword'].hasError('required')">
            Freight API Password is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight Carer</label>
          <input type="text" formControlName="freightCarrier" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightCarrier'].hasError('required')">
            Freight Carer is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight API Account</label>
          <input type="text" formControlName="freightAPIAccount" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIAccount'].hasError('required')">
            Freight API Account is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Freight API MasterAccount</label>
          <input type="text" formControlName="freightAPIMasterAccount" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIMasterAccount'].hasError('required')">
            Freight API MasterAccount is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Payment Carer</label>
          <input type="text" formControlName="paymentCarrier" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['paymentCarrier'].hasError('required')">
            Payment Carer is required
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Freight API URL</label>
          <input type="text" formControlName="freightAPIURL" class="form-control">
          <p class="text-danger" *ngIf="submitted && pharmacy.controls['freightAPIURL'].hasError('required')">
            Freight API URL is required
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Enable Freight API</label><br>
          <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
            <input type="checkbox" class="custom-control-input" id="custom-switch-3"
                   [checked]="freightUsingAPI"
                   (change)="Update_freightUsingAPI_mode($event)">
            <label class="custom-control-label mr-1" for="custom-switch-3">
              <span>{{ freightUsingAPI ? 'Active' : 'Inactive' }}</span>
            </label>
          </div>
        </div>
      </div>

      <h5 class="modal-title mt-1">Payment Details</h5>
      <hr>
      <div class="row">
        <div class="col-md-4 col-12">
          <label>Payment Type<small class="text-danger font-small-3">*</small><i
            class="ft-info text-info ml-1 cursor-pointer"
            (click)="collapse1 = !collapse1" [attr.aria-expanded]="!collapse1"></i></label><br>
          <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
            <input type="checkbox" class="custom-control-input" id="custom-switch-2" [checked]="!paymentType"
                   (change)="this.paymentType = !this.paymentType;">
            <label class="custom-control-label mr-1" for="custom-switch-2">
              <span>{{ paymentType ? 'Manual' : 'Online' }}</span>
            </label>
          </div>
          <p class="text-capitalize text-italic" aria-controls="collapseExample" [ngbCollapse]="collapse1">
            {{ paymentType ? 'By choosing the manual payment option, online payment is disabled.' : 'By selecting the online payment option, the customer will receive payment links through both SMS and email' }}
          </p>
        </div>
        <div class="col-md-4 col-12">
          <label>Payment Username</label>
          <input type="text" [(ngModel)]="paymentDataBody.paymentUserName" [ngModelOptions]="{standalone: true}" [value]="paymentDataBody.paymentUserName" class="form-control"
                 [readonly]="paymentType">
          <p class="text-danger" *ngIf="this.paymentDataBody.paymentUserName.trim() === '' && !paymentType">Payment
            Username is
            required</p>
        </div>
        <div class="col-md-4 col-6">
          <label class="form-label">Payment password</label>
            <input type="password" [(ngModel)]="paymentDataBody.password" [ngModelOptions]="{standalone: true}" [value]="paymentDataBody.password" [readonly]="paymentType"  class="form-control">
          <p class="text-danger" *ngIf="this.paymentDataBody.password.trim() === '' && !paymentType">Payment password is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-6">
          <label class="form-label">Biller Code</label>
          <input type="text" class="form-control" [(ngModel)]="paymentDataBody.billerCode" [ngModelOptions]="{standalone: true}" [value]="paymentDataBody.billerCode" [readonly]="paymentType">
          <p class="text-danger" *ngIf="this.paymentDataBody.billerCode.trim() === '' && !paymentType">Biller Code is
            required</p>
        </div>
        <div class="col-md-4 col-6">
          <label class="form-label">Merchant Number</label>
          <input type="text" class="form-control" [(ngModel)]="paymentDataBody.merchantNumber" [ngModelOptions]="{standalone: true}"
                 [value]="paymentDataBody.merchantNumber" [readonly]="paymentType">
        </div>
      </div>
      <div class="text-center px-2 py-2">
        <button class="btn secondary-btn" type="submit">Save</button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #view let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Pharmacy Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <!-- Card data starts -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <table class="table table-borderless">
                  <tbody>
                  <tr>
                    <td><b>Pharmacy Name:</b></td>
                    <td>{{ this.selectedPharmacy.pharmacy_name }}</td>
                  </tr>
                  <tr>
                    <td><b>Email:</b></td>
                    <td>{{ this.selectedPharmacy.email }}</td>
                  </tr>
                  <tr>
                    <td><b>Phone Number:</b></td>
                    <td>{{ this.selectedPharmacy.phoneNumber }}</td>
                  </tr>
                  <!--                  <tr>-->
                  <!--                    <td><b>Alternate Phone number:</b></td>-->
                  <!--                    &lt;!&ndash;                    <td>{{this.selectedCustomer.userId?.number}}</td>&ndash;&gt;-->
                  <!--                  </tr>-->
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <table class="table table-borderless">
                  <tbody>
                  <tr>
                    <td><b>Address:</b></td>
                    <td>{{ this.selectedPharmacy.address }}</td>
                  </tr>
                  <tr>
                    <td><b>Suburb:</b></td>
                    <td>{{ this.selectedPharmacy.suburb }}</td>
                  </tr>
                  <tr>
                    <td><b>State:</b></td>
                    <td>{{ this.selectedPharmacy.state }}</td>
                  </tr>
                  <tr>
                    <td><b>Postcode:</b></td>
                    <td>{{ this.selectedPharmacy.postcode }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--              <div class="col-6">-->
              <!--                <table class="table table-borderless">-->
              <!--                  <tbody>-->
              <!--                  <tr>-->
              <!--                    <td><b>Freight carrier Name:</b></td>-->
              <!--                  </tr>-->
              <!--                  <tr>-->
              <!--                    <td><b>Freight API key:</b></td>-->
              <!--                  </tr>-->
              <!--                  <tr>-->
              <!--                    <td><b>Freight API Password:</b></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--              </div>-->
              <!--              <div class="col-6">-->
              <!--                <table class="table table-borderless">-->
              <!--                  <tbody>-->
              <!--                  <tr>-->
              <!--                    <td><b>Payment carrier:</b></td>-->
              <!--                  </tr>-->
              <!--                  <tr>-->
              <!--                    <td><b>Payment Key:</b></td>-->
              <!--                  </tr>-->
              <!--                  <tr>-->
              <!--                    <td><b>Payment password:</b></td>-->
              <!--                  </tr>-->
              <!--                  <tr>-->
              <!--                    <td><b>Payment URL:</b></td>-->
              <!--                  </tr>-->
              <!--                  </tbody>-->
              <!--                </table>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <!-- Card data ends -->
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Pharmacy!</h4>
  </div>
  <div class="modal-body text-center">
    <p>Would you like to delete this Pharmacy?</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn btn-danger" (click)="deletePharmacy()">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>
