import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Json2csvService} from '../../../../shared/services/json2csv.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductComponent implements OnInit {

  productList: any[] = [];
  productName = '';
  userData: any;
  file: any;
  closeResult = '';
  selectedProduct: any;
  submitted = false;
  toogleSwitch = false;
  isloading = true;
  stockForm = new FormGroup({
    productName: new FormControl('', [Validators.required]),
    productSKU: new FormControl('', [Validators.required]),
    category: new FormControl(''),
    stock: new FormControl('', [Validators.required]),
    cost: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*')]),
  });

  page1 = 1;
  validationError = false;

  categoryList: any[] = [];
  selectedCategory: any;
  pharmacyList: any[] = [];
  selectedPharmacy: any;
  platform: any;
  createdProd: any;
  GlobalproductList: any = [];
  pharmacyId = '';

  response: any = [];
  nextpageLength = [];
  limitRecords = 10;
  searchBy = 'pagination';
  loadPaginationNum: any[] = [];
  pageTracker = 1;
  maxPageSize = 120;
  flag = 0;


  constructor(private authService: AuthService, private modalService: NgbModal, private storage: LocalStorageService,
              private jsonToCSV: Json2csvService, private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        sessionStorage.setItem('pharmacyId', data);
        this.pharmacyId = data
        this.loadingScreen();
        this.loadProducts(data);
        this.loadCategories();
        this.loadGlobalProd();
      }
      return;
    });
    this.loadProducts(sessionStorage.getItem('pharmacyId'));
    this.loadCategories();
    this.loadGlobalProd();
    for (let i = 1; i <= 10; i++) {
      this.loadPaginationNum.push({num: i, status: false});
    }
  }

  loadGlobalProd() {
    this.authService.fetch_global_prods(this.userData, this.platform, 1).then((resp: any) => {
      this.GlobalproductList = resp;
    }).catch(err => {
    });
  }


  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }

  loadProducts(pharmacyId) {
    this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, this.platform, 1, '', this.limitRecords).then((resp: any) => {
      if (resp.status === 404) {
        this.isloading = false;
        this.productList = [];
        return;
      }
      this.productList = resp.results;
      this.maxPageSize = resp.totalPages;
      this.isloading = false;
      this.spinner.hide();
    }).catch(err => {
      this.isloading = false;
      this.productList = [];
    });
  }


  loadCategories() {
    this.authService.fetch_catetories(this.userData, this.platform).then((resp) => {
      this.categoryList = resp.data;
    });
  }

  open(content, product) {
    this.selectedProduct = product;
    this.modalService.open(content, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openCreateModal(create) {
    this.stockForm.reset();
    this.modalService.open(create, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openModal(content, product) {
    this.selectedProduct = product;
    this.toogleSwitch = product.isDiscontinued;
    this.stockForm.controls['productName'].setValue(this.selectedProduct.productName);
    this.stockForm.controls['productSKU'].setValue(this.selectedProduct.productSKU);
    this.stockForm.controls['cost'].setValue(this.selectedProduct.productCost);
    this.stockForm.controls['stock'].setValue(this.selectedProduct.stock);

    this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get stk() {
    return this.stockForm.controls;
  }

  deleteProduct() {
    this.authService.deletePharcyProduct(this.userData, this.selectedProduct, this.platform).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.error('Pharmacy Data entry not found.', 'Deleted', {
          positionClass: 'toast-top-center'
        });
        this.modalService.dismissAll();
      } else {
        this.toastr.error('Product deleted successfully.', 'Deleted', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        this.modalService.dismissAll();
      }

    }).catch(err => {
      this.toastr.error('Product deletion failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }

  editProduct(toogle_Switch) {
    this.submitted = true;
    if (this.stockForm.invalid) {
      return;
    }
    let body: any = {};
    // @ts-ignore
    for (const key in this.stockForm.controls) {
      if (this.stockForm.controls[key].dirty) {
        if (key === 'cost') {
          body = {...body, ['cost']: parseFloat(this.stockForm.controls[key].value)};
        } else if (key === 'stock') {
          // @ts-ignore
          body = {...body, ['stock']: this.stockForm.controls['stock'].value === 0 ? '0' : this.stockForm.controls['stock'].value};
        } else {
          body = {...body, [key]: this.stockForm.controls[key].value};
        }
      }
    }

    if (toogle_Switch.checked) {
      this.toogleSwitch = toogle_Switch;
    }

    if (this.toogleSwitch !== this.selectedProduct.isDiscontinued) {
      body = {...body, ['isDiscontinued']: String(toogle_Switch.checked)};
    }

    this.authService.editProduct(this.userData, this.selectedProduct, body, this.platform).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.error(resp.error.error, 'Failed', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Product updated successfully.', 'Updated', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        this.modalService.dismissAll();
      }

    }).catch(err => {
    });
  }


  uploadCSv(file: any) {
    this.file = file.target.files[0];
    this.authService.upload_csv(this.userData, this.file, this.platform).then((res: any) => {
      this.toastr.success('Product addition successfull', 'Success', {
        positionClass: 'toast-top-center'
      });
      this.loadProducts(this.pharmacyId);
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Product details upload failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }


  OnSubmit() {
    this.submitted = true;
    if (this.stockForm.invalid) {
      return;
    }
    this.submitted = false;
    const body = {
      'productSKU': this.stockForm.value.productSKU,
      'stock': this.stockForm.value.stock,
      'cost': parseInt(this.stockForm.value.cost),
      'productName': this.stockForm.value.productName,
    }
    // this.authService.createProduct(this.userData, body).then((resp: any) => {
    //   this.toastr.success('Product created.', 'Success', {
    //     positionClass: 'toast-top-center'
    //   });
    //   this.loadProducts();
    //   this.modalService.dismissAll();
    // }).catch(err => {
    // });
  }

  openImportModal(importcsv) {
    this.modalService.open(importcsv, {size: 'md'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  download() {
    const arr = [];
    let obj = {
      'BrandName': '',
      'ProductName': '',
      'price': '',
      'productSKU': '',
      'stockAvailable': '',
      'originalName': '',
      'repeats': '',
      'intervalPeriod': ''
    };
    this.authService.fill_exel(this.userData, this.platform, sessionStorage.getItem('pharmacyId')).then((resp: any) => {
      this.response = resp.result;
      this.response.filter((data: any) => {
        obj.BrandName = data.brandName;
        obj.ProductName = data.productName;
        obj.productSKU = data.productSKU;
        obj.stockAvailable = data.stockAvailable;
        obj.price = data.price;
        obj.originalName = data.originalName;
        obj.repeats = '';
        obj.intervalPeriod = '';
        arr.push(obj);
        obj = {
          'BrandName': '',
          'ProductName': '',
          'price': '',
          'productSKU': '',
          'stockAvailable': '',
          'originalName': '',
          'repeats': '',
          'intervalPeriod': ''
        };
      });
      this.jsonToCSV.downloadCsv(arr, 'SampleProducts.csv');
    })
  }


  currentPage() {
    const pharmacyId = sessionStorage.getItem('pharmacyId');
    this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, this.platform, this.pageTracker, '', this.limitRecords).then((resp: any) => {
      this.productList = resp.results;
    }).catch(err => {
    });
  }


  filterUpdate(value: string) {
    this.flag = 0;
    this.searchBy = 'search';
    const pharmacyId = sessionStorage.getItem('pharmacyId');
    this.productName = value.trim();
    if (value.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Enter Product Name.', 'Info', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, this.platform, 1, value.trim(), this.limitRecords).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.error('Product not found', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (resp.results.length === 0) {
        this.toastr.error('Product not found', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.productList = [];
        this.maxPageSize = 0;
        return;
      } else {
        this.productList = resp.results;
        this.maxPageSize = resp.totalPages;
      }

    })
  }

  pagination(num: number) {
    this.pageTracker = num;
    const pharmacyId = sessionStorage.getItem('pharmacyId');
    if (this.searchBy === 'search') {
      this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, this.platform, this.pageTracker, this.productName, this.limitRecords).then((resp: any) => {
        if (resp.status === 404) {
          this.toastr.clear();
          this.toastr.error('Product not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.productList = resp.results;
        this.maxPageSize = resp.totalPages;
      });
    } else {
      this.isloading = true;
      this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, this.platform, this.pageTracker, '', this.limitRecords).then((resp: any) => {
        this.productList = resp.results;
        this.isloading = false;
        this.maxPageSize = resp.totalPages;
      }).catch(err => {
        this.isloading = false;
        this.productList = [];
      });
    }

  }

  OnChangeLimit(event) {
    this.searchBy = 'pagination';
    this.pageTracker = 1;
    this.limitRecords = Number(event.target.value);
    const pharmacyId = sessionStorage.getItem('pharmacyId');
    this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, this.platform, this.pageTracker, '', this.limitRecords).then((resp: any) => {
      this.productList = resp.results;
      this.maxPageSize = resp.totalPages;
    });
  }

  clearbox() {
    this.flag = this.flag + 1;
    if (this.flag === 1) {
      this.loadProducts(this.pharmacyId);
      this.searchBy = 'pagination';
    }
  }
}
