<section id="ngx">
  <div class="card">
    <div class="card-header">
      <h4>Order Details</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form>
          <br/>
          <h5 class="font-weight-bold font-italic">User Information</h5>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-8 col-12">
              <div class="row">
                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                  <label class="font-weight-bold">Name</label>
                  <p>{{ selectedOrder?.name }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Mobile Number</label>
                  <p>{{ selectedOrder?.mobile }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Email</label>
                  <p style="word-break: break-word">{{ selectedOrder?.email }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Order By</label><br>
                  <p>{{ selectedOrder?.orderedBy }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Ordered Date and Time</label><br>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                     *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                  <label class="font-weight-bold">Cancellation Reason</label><br>
                  <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                  <p
                    *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
                </div>

                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Delivery Type</label><br>
                  <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                  <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                  <label class="font-weight-bold">Status</label><br>
                  <span
                    class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : pharmacyUser?.pharmacyUserStatus === 'verified','bg-light-dark btn' : pharmacyUser?.pharmacyUserStatus === 'blacklisted',
                            'bg-light-danger btn': pharmacyUser?.pharmacyUserStatus === 'unverified'}">
                              {{ pharmacyUser?.pharmacyUserStatus }}</span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                  <label class="font-weight-bold">Order Status</label><br>
                  <span
                    *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                    class="text-capitalize">{{ selectedOrder.status }}</span>
                  <span
                    *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                    class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                  <label class="font-weight-bold">Address</label>
                  <p>
                    <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                  </p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                     *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                  <label class="font-weight-bold">Pickup Name</label><br>
                  <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                     *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                  <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                    <i
                      *ngIf="showUrl"
                      class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                      (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                  </label>
                  <br>
                  <div *ngIf="showUrl">
                    <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                         height="40"
                         (click)="isCollapsed = !isCollapsed"
                         [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                    <div id="collapseExample" [ngbCollapse]="isCollapsed">
                      <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                    </div>
                  </div>
                  <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-4 col-12">
              <div class="chat-container" *ngIf="this.customerNotes.length > 0;">
                <div class="chat-header bg-secondary">Customer Follow Up Note</div>
                <div class="chat-box">
                  <div class="messages">
                    <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                      {{ note.fallowUp_note }}
                      <p
                        class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                        {{ note.note_addedBy_userName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <h5 class="font-weight-bold font-italic">User Script</h5>
            <hr>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead class="thead-light">
              <tr>
                <!--              <th class="text-left">QR Code</th>-->
                <th class="text-left">Token Number</th>
                <th class="text-left">Script Info</th>
                <th>Brand Name</th>
                <th class="text-left">Product Name</th>
                <!--              <th>Medication Strength</th>-->
                <th>Quantity</th>
                <th>Shipped Quantity</th>
                <th>Remaining Quantity</th>
                <th>Price</th>
                <!--              <th>Repeats</th>-->
                <th>Instruction</th>
                <th>Note</th>
                <th>Backorder</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.selectedOrder?.orderedItem;let i = index;">
                <!--              <td class="py-0">-->
                <!--                <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
                <!--              </td>-->
                <td>{{ item?.token }}</td>
                <td class="text-center">
                  <div class="d-flex">
                    <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                         class="cursor-pointer"
                         src="{{ item?.scriptData?.uploadedScript }}"
                         alt="" width="60" height="40"
                         (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
                    <span ngbTooltip="Download ScriptImage"
                          *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                          class="ft-download-cloud cursor-pointer ml-2"
                          (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
                  </div>
                  <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
                     href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
                  </a>
                </td>
                <td class="text-left">{{ item?.product_brand_name }}</td>
                <td class="text-left cursor-pointer"
                    (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
                </td>
                <!--              <td>{{item?.medication_strength}}</td>-->
                <td>X{{ item?.quantity }}</td>
                <td>{{ item?.shippedQuantity }}</td>
                <td>{{ item?.remainingQuantity }}</td>
                <td>${{ item?.price }}</td>
                <!--              <td>{{item?.repeats}}</td>-->
                <td>{{ item?.instruction }}</td>
                <td>{{ item?.note }}</td>
                <td *ngIf="item.isBackOrder" class="text-center">
                 <span
                   class="badge bg-light-warning btn text-capitalize">Backorder</span>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Item Total</b></td>
                <td>${{ selectedOrder?.itemsCost | number:'1.2-2' }}</td>
              </tr>
              <tr *ngIf="!selectedOrder?.pickup">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Delivery Charge:</b></td>
                <td>
                  ${{ selectedOrder?.pharmacyId.freightCharge }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Tax Amount (10%)</b></td>
                <td>
                  ${{ selectedOrder?.taxAmount }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Total Amount:</b></td>
                <td class="font-weight-bold">${{ selectedOrder?.totalAmount }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <span class="text-info mt-1"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>
          <br>
          <br>
          <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
          <hr>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Name</label>
                <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
              </div>
              <div class="col-12 mt-2">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Email</label>
                <p>{{ selectedOrder?.pharmacyId?.email }}</p>
              </div>
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Address</label>
                <p>{{
                    selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                    ' ' + selectedOrder?.pharmacyId?.postcode
                  }}</p>
              </div>
            </div>
          </div>
          <!--Additional Cost -->
          <br>
          <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional Costs</h5>
          <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
            <div class="col-md-12 col-12">
              <div class="table-responsive">
                <table class="table m-0">
                  <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Payment Status</th>
                    <th>Amount Added By</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                    <td>{{ extracost.amount }}</td>
                    <td>{{ extracost.description }}</td>
                    <td>   <span [ngClass]="extracost.paid ? 'bg-light-success' : 'bg-light-secondary'"
                                 class="badge ">{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</span></td>
                    <td>{{ extracost.amount_addedBy_userName }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <span *ngIf="selectedOrder?.additionalCosts.length > 0;" class="text-info mt-1"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>
          <!--      Payment Details-->
          <br>
          <div
            *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
            <h5 class="font-weight-bold font-italic">Payment Details
            </h5>
            <hr>
            <div class="table-responsive">
              <table class="table table-bordered m-0">
                <thead class="table-header">
                <tr>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Payment Method</th>
                  <th>Transaction Number</th>
                  <th>Date & Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                  <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                  <td>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </td>
                  <td>{{ payment?.orderPayment_method === 'Internet' ? 'Online Payment' : payment?.orderPayment_method }}</td>
                  <td>{{ payment?.orderPayment_reference }}</td>
                  <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <span class="text-info mt-1" *ngIf="selectedOrder.orderPaymentDetails.length > 0;"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>

          <!--      Delivery Details-->
          <br>
          <div *ngIf="selectedOrder?.shippingDetails.length > 0">
            <h5 class="font-weight-bold font-italic">Delivery Details</h5>
            <hr>
            <div class="table-responsive">
              <table class="table table-bordered m-0">
                <thead class="table-header">
                <tr>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Carrier Number</th>
                  <th>Connote Number</th>
                  <th>Delivery Date & Time</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                  <td class="cursor-pointer"
                      (click)="viewChildOrder(ele?.itemShippedDetails[0]?.orderNumber,productsShipped)">
                    <span style="text-decoration: underline;">{{ ele?.itemShippedDetails[0]?.orderNumber }}</span>
                  </td>
                  <td>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </td>
                  <td>{{ ele?.freightCarrier }}</td>
                  <td>{{ ele?.trackingNo }}</td>
                  <td>{{ ele?.deliveredDate  | date:'mediumDate' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <span *ngIf="selectedOrder?.shippingDetails.length > 0;" class="text-info mt-1"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>


<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>


<ng-template #productsShipped let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Shipped Products - #{{ childOrder.orderNo }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
        <tr>
          <!--          <th class="text-left">QR Code</th>-->
          <th>Token Number</th>
          <th>Brand Name</th>
          <th>Product Name</th>
          <th>Ordered Quantity</th>
          <th>Shipped Quantity</th>
          <th>Remaining Quantity</th>
        </tr>
        </thead>
        <tr *ngFor="let item of this.childOrder.orderedItem;let i = index;">
          <td><span (click)="copyToken(item?.token)" class="text-UpperCase"><i class="cursor-pointer ft-copy mr-1 text-primary"
                                                                               ngbTooltip="Token copied"
                                                                               triggers="click:blur"></i></span>{{ item?.token }}
          </td>
          <td>{{ item?.product_brand_name }}</td>
          <td class=" cursor-pointer" (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}</td>
          <td>{{ item?.quantity }}</td>
          <td>{{ item?.shippedQuantity }}</td>
          <td>{{ item?.remainingQuantity }}</td>
        </tr>
        <tbody>
        </tbody>
      </table>
      <span class="text-info mt-1"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>
    </div>
  </div>
</ng-template>
