<p class="text-center font-weight-bold">Script IT – PRIVACY POLICY</p>
<p class="text-justify">
  We are bound by the Privacy Act 1988 (Cth) <b>(Privacy Act)</b>  and the Australian Privacy Principles (APPs). This policy
  explains how and why we collect, use, hold and disclose your personal information.
  <br><br> <b>“We”</b>, <b>“us”</b> and <b>“our”</b> means Chemsoft Pty Ltd, ABN: 49 675 969 847, (07) 3180 9737 of Level 5/445 Edward St,
  Spring Hill, QLD 4000.<br>
  We operate a business that provides a secure platform to submit pharmaceutical prescriptions and obtain access to various products via partner pharmacies through an e-commerce platform.
  <br><br>
  You consent to us collecting, holding, using, and disclosing your personal information in accordance with this policy.
</p>

<dl>
  <dt>WHAT IS PERSONAL INFORMATION?</dt>
  <dd>Personal information is any information or an opinion about an identified individual or an individual who can be reasonably identified from the information or opinion. Information or an opinion may be personal information regardless of whether it is true.

  </dd>
</dl>

<dl>
  <dt>WHAT PERSONAL INFORMATION DO WE COLLECT AND HOLD?</dt>
  <dd>
    We collect information about you and your interactions with us, for example, when you use our platform or visit our website. The information we collect from you may include your identity and contact details, your history of use of our platform and details of any enquiries or complaints you make. We may collect names, dates of birth, email addresses, signatures, mailing addresses, residential addresses, telephone numbers, Medicare numbers, copies of government-issued identification, health fund details, healthcare provider details, medications or pharmaceutical products prescribed, prescriptions and script IDs.

  </dd>
</dl>

<p>We may also collect the following information:</p>
<ol type="a">
  <li>the location from which you have come to the site and the pages you have visited; and</li>
  <li>technical data, which may include IP addresses, the types of devices you are using to access the website, device
    attributes, browser type, language and operating system
  </li>
</ol>

<p class="text-justify">
  We use cookies on the website. A cookie is a small text file that the website may place on your device to store
  information. We may use persistent cookies (which remain on your computer even after you close your browser) to store
  information that may speed up your use of our website for any of your future visits to the website. We may also use
  session cookies (which no longer remain after you end your browsing session) to help manage the display and
  presentation of information on the website. You may refuse to use cookies by selecting the appropriate settings on
  your browser. However, please note if you do this, you may not be able to use the full functionality of the product.
</p>


<p><b>WHY DO WE COLLECT, HOLD AND USE YOUR PERSONAL INFORMATION?</b>
  <br><br>
  We collect, hold, and use your personal information so that we can:
</p>
<ol type="a">
  <li>manage our relationship with you;</li>
  <li>provide you (or a subject of care) with the capability to order prescription and medical products from pharmacies,
    and to enable employees of such pharmacies to fulfil such orders;
  </li>
  <li>process, confirm, and update you about your order(s);</li>

  <li>communicate with you and provide you with information (whether by email, post or other means) about our products
    or services, where you have requested or consented to receiving this from us or where this provision is otherwise
    permitted under the Australian Privacy Principles;
  </li>

  <li>to facilitate communication by third parties (whether by email, post or other means) in relation to products or
    services that may be of interest to you, where you have requested or consented to us providing your personal
    information to third parties for that purpose;
  </li>
  <li>enable research and market analysis, where you have consented to same;
  </li>
  <li>notify you about changes to our goods and services;
  </li>
  <li>contact you, for example, to respond to your queries or complaints, or if we need to tell you something important;
  </li>
  <li>comply with our legal obligations and assist government and law enforcement agencies or regulators; or
  </li>
  <li>identify and tell you about other products or services that we think may be of interest to you.
  </li>
</ol>


<p class="text-justify">
  By using the platform, you consent to the use of your personal information for the above purposes.
  <br><br>
  All users and others with whom we interact have the option to opt-out of receiving marketing communications from us. If you do not wish to continue to receive electronic marketing communications from us and/or selected third parties, you should opt-out by clicking on the “unsubscribe” link in any email communications that we might send you.

  <br><br>
  If you request us not to send other electronic communications, we will also comply with that request to the extent reasonably practical.

  <br>
</p>
<p class="font-weight-bold">
  HOW DO WE COLLECT YOUR PERSONAL INFORMATION
</p>
<p class="text-justify">
  We will collect your personal information directly from you whenever you interact with us.

</p>
<ol type="a">
  <li>healthcare providers;</li>
  <li>pharmacies or chemists; and</li>
  <li>eRx (script exchange).</li>
</ol>
<p class="font-weight-bold">
  HOW DO WE STORE AND HOLD PERSONAL INFORMATION?
</p>
<p class="text-justify">
  We store most information about you in encrypted computer systems and databases operated by either us or our external service providers. Some information about you is recorded in paper files that we store securely.

  <br><br>
  We implement and maintain processes and security measures to protect personal information which we hold from misuse, interference or loss, and from unauthorised access, modification or disclosure.

  <br><br>
  However, unfortunately, the internet is not always a secure place, and we cannot guarantee total security of your personal information in all circumstances.

  <br><br>
  When you provide personal information to us, you warrant that it is accurate, current, and complete and undertake to maintain the accuracy, currency, and completeness of the personal information we retain.

  <br><br>
  We will take reasonable steps to destroy or de-identify personal information if it is no longer needed for any purpose for which it may be used or disclosed in accordance with the Privacy Act.

  <br>

</p>

<p class="font-weight-bold">
  WHO DO WE DISCLOSE YOUR PERSONAL INFORMATION TO, AND WHY?
</p>
<p class="text-justify">
  We may transfer or disclose your personal information to our related companies.<br><br>
  We may disclose personal information to external service providers, including pharmacies, so that they may perform
  services for us or on our behalf, for example:
</p>
<ol type="a">
  <li>Organisations who carry out fraud and other security checks;</li>
  <li>Couriers and delivery businesses (where we arrange to deliver goods to you or persons have requested us to send
    deliveries to);
  </li>
  <li>Third party suppliers of goods or services that may be of interest to you (with your consent);</li>
  <li>Marketing businesses engaged by us to disseminate materials to which recipients have consented (if applicable).
  </li>
</ol>


<p class="text-justify">We may also disclose your personal information to others outside our group of companies where:
</p>
<ol type="a">
  <li>We are required or authorised by law to do so;</li>
  <li>You may have expressly consented to the disclosure or the consent may be reasonably inferred from the
    circumstances; or
  </li>
  <li>We are otherwise permitted to disclose the information under the Privacy Act.</li>
</ol>

<p class="text-justify">
  Except as above, we limit the information we provide to third parties to the information they need to help us and partner pharmacies to provide or facilitate the provision of goods and services to you.

  <br><br>
  If ownership or control of all or part of our business changes, we may transfer your personal information to the new owner.

</p>
<p>
  DO WE DISCLOSE PERSONAL INFORMATION TO OVERSEAS RECIPIENTS?<br><br>
  We do not disclose your personal information to recipients which are located outside Australia.
  <br><br>
  ACCESS TO AND CORRECTION OF YOUR PERSONAL INFORMATION
  <br><br>
  You may access or request correction of the personal information that we hold about you by contacting us. Our contact details are set out below. There are some circumstances in which we are not required to give you access to your personal information.

  <br><br>
  There is no charge for requesting access to your personal information, but we may require you to meet your reasonable costs in providing you with access (such as printing costs or costs for time spent collating information).

  <br><br>
  We will respond to your requests to access or correct personal information in a reasonable time and will take all reasonable steps to ensure that the personal information we hold about you remains accurate, up to date, relevant, and not misleading.

</p>

<dt>Contact details:</dt>
<dd>Postal address: 7 Butterfield St, Herston, QLD 4006 <br>
  Email address: info@scriptit.com.au
</dd>
