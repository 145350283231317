import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ErrorPageComponent} from './error/error-page.component';
import {LoginPageComponent} from './login/login-page.component';
import {RegisterComponent} from './register/register.component';
import {CustomerLoginComponent} from './customer-login/customer-login.component';
import {LaunchScreenComponent} from './launch-screen/launch-screen.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordPageComponent} from './reset-password-page/reset-password-page.component';
import {PaymentComponent} from './payment/payment.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          title: 'Error Page'
        }
      },
      {
        path: 'login',
        component: LoginPageComponent,
        data: {
          title: 'Login Page'
        }
      }, {
        path: 'register',
        component: RegisterComponent,
        data: {
          title: 'Register Page'
        }
      }, {
        path: 'customer-login',
        component: CustomerLoginComponent,
        data: {
          title: 'Register Page'
        }
      },
      {
        path: 'launch',
        component: LaunchScreenComponent,
        data: {
          title: 'Launch screen'
        }
      }, {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
          title: 'Forgot password'
        }
      }, {
        path: 'resetPassword',
        component: ResetPasswordPageComponent,
        data: {
          title: 'Reset password'
        }
      }, {
        path: 'payment',
        component: PaymentComponent,
        data: {
          title: ''
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentPagesRoutingModule {
}
