import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {


  userData: any;
  user: any;

  constructor(private authService: AuthService, private modalService: NgbModal, private toastr: ToastrService, private storage: LocalStorageService,
              private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.authService.fetchUser(this.userData).then((reps: any) => {
      this.user = reps;
    });

  }


  enable(property: string, event: any) {
    const body = [{
      propName: property,
      value: event.target.checked
    }];
    this.authService.update_user_details(this.userData, body).then((resp: any) => {
      this.user = resp;
    })

  }

  enableTwoFactAuth(event: any) {
    // twoFactorAuth
    let body = [{
      propName: 'twoFactorAuth.enabled',
      value: event.target.checked
    }];
    this.authService.update_user_details(this.userData, body).then((resp: any) => {
      this.user = resp;
    })
  }

  openModal(confirmModal) {
    this.modalService.open(confirmModal, {size: 'md', backdrop: 'static'})
  }

  deactivate_My_Acc(accept: HTMLInputElement, modal) {
    let deletePrescription = 'false';
    if (!accept.checked) {
      deletePrescription = 'false';
    } else {
      deletePrescription = 'true'
    }
    this.authService.delete_User(this.userData, this.userData.auth.data, deletePrescription).then((resp) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Account deactivation failed', 'Error!', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.error('Your account is deactivated', 'Deactivated', {
        positionClass: 'toast-top-center'
      });
      modal.dismiss('Cross-click');
      this.authService.logout();
      this.router.navigate(['/pages/launch']);

    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Your account is deactivation id failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }
}
